import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutsRoutingModule } from './layouts-routing.module';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared/shared.module';
import { CountToModule } from 'angular-count-to';
import { AngularTiltModule } from 'angular-tilt';
import { ScrollToModule } from 'ng2-scroll-to-el';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Ng5SliderModule } from 'ng5-slider';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CarouselModule as NgbCarousel } from 'ngx-bootstrap/carousel';

// Enterprice SASS Layout
import { LandingHomeComponent } from './landing-home/landing-home.component';
import { LandingHomeNavComponent } from './landing-home/landing-home-nav/landing-home-nav.component';
import { LandingHomeHeaderComponent } from './landing-home/landing-home-header/landing-home-header.component';
import { LandingHomeFeatureComponent } from './landing-home/landing-home-feature/landing-home-feature.component';
import { LandingHomeBuildComponent } from './landing-home/landing-home-build/landing-home-build.component';
import { LandingHomeWorkComponent } from './landing-home/landing-home-work/landing-home-work.component';
import { LandingHomePriceComponent } from './landing-home/landing-home-price/landing-home-price.component';
import { LandingHomeTestimonialComponent } from './landing-home/landing-home-testimonial/landing-home-testimonial.component';
import { LandingHomeSubscribeComponent } from './landing-home/landing-home-subscribe/landing-home-subscribe.component';
import { LandingHomeFaqComponent } from './landing-home/landing-home-faq/landing-home-faq.component';
import { LandingHomeClientComponent } from './landing-home/landing-home-client/landing-home-client.component';
import { LandingHomeFooterComponent } from './landing-home/landing-home-footer/landing-home-footer.component';
import { LandingHomeCopyrightComponent } from './landing-home/landing-home-copyright/landing-home-copyright.component';

import { NgxMasonryModule } from 'ngx-masonry';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthenticationInterceptor } from '../_services/auth/auth.interceptor';
import { AuthenticationService } from '../_services/auth/authentication.service';
import { ProfileComponent } from './profile/profile.component';
import { DocumentDownloadComponent } from './document-download/document-download.component';
import { DataTablesModule } from 'angular-datatables';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HelpdeskComponent } from './helpdesk/helpdesk.component';





const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {};

@NgModule({
    declarations: [
        LandingHomeComponent, LandingHomeNavComponent, LandingHomeHeaderComponent, LandingHomeFeatureComponent, LandingHomeBuildComponent, LandingHomeWorkComponent, LandingHomePriceComponent, LandingHomeTestimonialComponent, LandingHomeSubscribeComponent, LandingHomeFaqComponent, LandingHomeClientComponent, LandingHomeFooterComponent, LandingHomeCopyrightComponent, ProfileComponent, DocumentDownloadComponent, HelpdeskComponent,  ],

    imports: [
        CommonModule,
        LayoutsRoutingModule,
        SwiperModule,
        CarouselModule,
        NgbModule,
        SharedModule,
        CountToModule,
        AngularTiltModule,
        ScrollToModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        DataTablesModule,
        NgxDatatableModule,
        Ng5SliderModule,
        NgxMasonryModule,
        ModalModule.forRoot(),
        NgbCarousel.forRoot(),
        NgxSpinnerModule
    ],
    exports: [],
    providers: [
        {
            provide: SWIPER_CONFIG,
            useValue: DEFAULT_SWIPER_CONFIG
        },
        {
            provide: HTTP_INTERCEPTORS,
            multi: true,
            useClass: AuthenticationInterceptor
        },
        AuthenticationService
    ]
})

export class LayoutsModule {
}
