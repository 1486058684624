<!-- <div class="m-banner hidden" style="background-color: black; display: block; height: 66px;">
    <div id="big-banner" class="promociones">
        <div>
            <span style="font-weight: 300; color: #fff;">Tes</span>
        </div>
    </div>
</div> -->
<app-landing-home-nav></app-landing-home-nav>
<app-landing-home-header></app-landing-home-header>
<app-landing-home-feature></app-landing-home-feature>
<!-- <app-landing-home-build></app-landing-home-build>
<app-landing-home-work></app-landing-home-work>
<app-landing-home-price></app-landing-home-price>
<app-landing-home-testimonial></app-landing-home-testimonial>
<app-landing-home-subscribe></app-landing-home-subscribe>
<app-landing-home-faq></app-landing-home-faq>
<app-landing-home-client></app-landing-home-client> -->
<app-landing-home-footer></app-landing-home-footer>
<app-landing-home-copyright></app-landing-home-copyright>

<div bsModal #lgModal="bs-modal" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="dialog-sizes-name1">
    <div class="modal-dialog modal-xl" onmouseenter="$('#carouselExampleControls, #titleCarousel').carousel('pause')"
         onmouseleave="$('#carouselExampleControls, #titleCarousel').carousel('cycle')">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="lgModal.hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" style="text-align: center;">
                <div class="row align-middle">
                    <div style="width: 10%" class="vertical-center">
                        <a *ngIf="listPengumuman.length > 1" class="carousel-control" style="font-size: 40px; color: #fb3b64 !important;"
                           href="#carouselExampleControls"
                           data-slide="prev"
                           onclick="$('#carouselExampleControls, #titleCarousel').carousel('prev')"><i
                                class="fa fa-arrow-circle-left"></i></a>
                    </div>
                    <div style="width: 80%;">
                        <!-- <carousel [isAnimated]="true" [showIndicators]="false">
                            <slide *ngFor="let data of listPengumuman">
                                <span>{{data.uraianPengumuman}}</span>
                            </slide>
                        </carousel> -->
                        <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                            <div class="carousel-inner">
                                <div *ngFor="let data of listPengumuman; let i = index" class="carousel-item"
                                     [class]="i == 0 ? 'active' : ''">
                                    <section class="shadow-section">
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="title title2 title-inner">
                                                        <div class="main-title">
                                                            <h5 class="font-primary borders text-center main-text m-b-0"><span
                                                                    class="text-uppercase">{{data?.namaPengumuman}}</span>
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    <div class="shadow p-5 shadow-showcase text-center ">
                                                        <!-- <p class="m-0 f-18 text-center shadow-font">{{data?.uraianPengumuman}}</p> -->
                                                        <span style="white-space: pre-wrap" class="m-0 f-18 text-center shadow-font">{{data?.uraianPengumuman}}</span>
                                                        <!-- <pre class="m-0 f-18 text-center shadow-font">{{data?.uraianPengumuman}}</pre> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="width: 10%;" class="vertical-center">
                        <a *ngIf="listPengumuman.length > 1" class="carousel-control"
                           style="font-size: 40px; color: #fb3b64 !important;"
                           href="#carouselExampleControls" data-slide="next"
                           onclick="$('#carouselExampleControls, #titleCarousel').carousel('next')"><i
                                class="fa fa-arrow-circle-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="row align-content-center text-center align-middle">
                    <div class="co-12 align-content-center text-center align-middle">
                        <button type="button" class="btn btn-dark" (click)="lgModal.hide()">Tutup</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
