<!-- header section Start-->
<section class="saas1 header" id="home">
  <div
    class="saas1-header bg header8-content"
    [ngStyle]="{
      'background-image': 'url(assets/images/saas1/slider-banner.jpg)'
    }"
  >
    <div class="container">
      <div class="row">
        <div class="col-md-7">
          <div class="center-text">
            <div>
              <div class="header-text">
                <div class="d-flex">
                  <h1>
                    <span class="theme-color">S</span>istem
                    <span class="theme-color">I</span>nformasi
                    <span class="theme-color">P</span>emerintahan
                    <span class="theme-color">D</span>aerah
                  </h1>
                  <div class="center-content slider-logo"></div>
                </div>
              </div>
              <div class="header-sub-text">
                <h3 class="text-white">
                  Landing Page For<span> SIKD</span> DKI JAKARTA
                </h3>
              </div>
              <div class="link-horizontal">
                <ul>
                  <li *ngIf="!userLogin">
                    <a
                      class="btn btn-default primary-btn transparent"
                      (click)="goToLogin()"
                      >Login</a
                    >
                  </li>
                  <li *ngIf="userLogin">
                    <a
                      class="btn btn-default primary-btn transparent"
                      (click)="signOutAlert()"
                      >Log Out</a
                    >
                  </li>
                  <!-- <li>
                                        <a class="btn btn-default primary-btn transparent">start now</a>
                                    </li> -->
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <img src="assets/images/saas1/dot.png" class="img-fluid set-abs dot" alt="" />
</section>
<!-- header section end-->
