import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { OpdModel } from 'src/app/_model/opd.model';
import { DatatablesModelResponse } from 'src/app/_model/utils/datatables.model';
import { HelpdeskModel } from './helpdesk.model';

@Injectable({
  providedIn: 'root'
})
export class HelpdeskService {

  constructor(private http: HttpClient) { }

  public listCodeTiket(namaJenis : String) {
    return this.http.get<HelpdeskModel[]>(`${environment.uriSipkdReferensi}/api/codeTiket/list/${namaJenis}`)
  }

  public opdDatatable(parameters: any, value: OpdModel) {
    let params = new HttpParams();
    params = params.append('start', parameters.start);
    params = params.append('length', parameters.length);
    params = params.append('draw', parameters.draw);
    params = params.append('order[0][column]', parameters.order[0]['column']);
    params = params.append('order[0][dir]', parameters.order[0]['dir']);
    return this.http.post<DatatablesModelResponse>(
        `${environment.uriSipkdReferensi}/api/referensi-data/available-skpd-helpdesk`, value, { params: params });
  }

  public create(value:HelpdeskModel){
    return this.http.post(`${environment.uriSipkdReferensi}/api/helpdesk/save`,value,{observe:"response"})
  }

}
