<header class="ecommerce">
    <div class="container">
        <div class="row">
            <div class="col">
                <nav>
                    <a href="#" class="d-inline-block m-r-auto">
                        <img src="assets/images/logo/dki_logo.png" width="100px" alt="" class="img-fluid">
                        <strong style="color: black;">SIPD DKI JAKARTA</strong>
                    </a>
                    <app-menu></app-menu>
                </nav>
            </div>
        </div>
    </div>
</header>

<section id="feature" class="saas1 service">
    <div class="about-chat">
        <div class="container">
            <div class="row" style="padding: 20px;">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4>Helpdesk</h4>
                        </div>

<!-- <div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card"> -->
                <div class="card-body">
                    <form [formGroup]="formTiket" class="form theme-form">
                        <div class="row p-t-10">
                            <div class="col col-xl-6 col-lg-6 col-md-6 col-xs-12 col-sm-12">

                                <div class="form-group row">
                                    <label class="col-sm-4 col-form-label">ID SKPD</label>
                                    <div class="col-sm-8">
                                        <div class="input-group input-group-sm">
                                            <input style="font-size: 13px;" class="form-control" readonly id="idOpd" name="idOpd"
                                                formControlName="idOpd">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary search fa fa-search"
                                                    (click)="listSkpdModal.toggle()" type="button"></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label class="col-sm-4 col-form-label">Kode SKPD</label>
                                    <div class="col-sm-8">
                                        <div class="input-group input-group-sm">
                                            <input style="font-size: 13px;" class="form-control" readonly id="codeOpd" name="codeOpd"
                                                formControlName="codeOpd">
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label class="col-sm-4 col-form-label">Module</label>
                                    <div class="col-sm-8">
                                        <select class="form-control" formControlName="codeModule"
                                        placeholder="Pilih Module">
                                        <option *ngFor="let data of listModule" value="{{data.idTHelpdesk}}">{{data.namaTHelpdesk}}</option>
                                    </select>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label class="col-sm-4 col-form-label">Topic</label>
                                    <div class="col-sm-8">
                                        <div class="input-group input-group-sm">
                                            <input class="form-control" id="namaTopic" name="namaTopic"
                                            formControlName="namaTopic" rows="6" placeholder="Masukkan Topic">
                                        </div>  
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label class="col-sm-4 col-form-label">Jenis</label>
                                    <div class="col-sm-8">
                                        <select class="form-control" name="codeJenis" style="font-size: 13px;"
                                            formControlName="codeJenis" id="codeJenis">
                                            <option *ngFor="let data of listJenis" value="{{data.idTHelpdesk}}">{{data.namaTHelpdesk}}</option>
                                        </select>  
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label class="col-sm-4 col-form-label">Message</label>
                                    <div class="col-sm-8">
                                        <div class="input-group input-group-sm">
                                            <textarea class="form-control" id="message" name="message"
                                            formControlName="message" rows="6" placeholder="Message"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-grid gap-2 col-4 mx-auto p-t-15">
                            <button type="button" class="btn btn-dark" (click)="goBack()"><i
                                    class="fa fa-close"></i> Kembali
                            </button>
                            &nbsp;
                            <button type="button" class="btn btn-success" (click)="save()"><i class="fa fa-check"></i>
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>

        </div>
    </div>
</div>
</div>
</section>


<app-landing-home-footer></app-landing-home-footer>
<app-landing-home-copyright></app-landing-home-copyright>

<div bsModal id="listSkpdModal" #listSkpdModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <strong class="modal-title">Pilih SKPD</strong>
                <button type="button" class="close" (click)="listSkpdModal.hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <!-- Container-fluid starts-->
                <form class="theme-form billing-form row col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12"
                    [formGroup]="formSearch" (submit)="refresh()">
                    <div class="form-group col-xl-7 col-lg-7 col-md-7 col-xs-12 col-sm-12">
                        <small class="form-text text-muted">Nama SKPD</small>
                        <div class="input-group">
                            <input style="font-size: 13px;" type="text" class="form-control" id="namaOpd" name="namaOpd"
                                formControlName="namaOpd" placeholder="Nama">
                            <div class="input-group-prepend">
                                <button type="submit" class="btn btn-primary fa fa-search">
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-md-12 col-sm-12 col-lg-12 col-xs-push-12 col-xl-12 p-t-10">
                        <div class="table-responsive">
                            <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                                class="row-border hover table table-bordered table-sm">
                            </table>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer justify-content-center">
                <button class="btn btn-dark" (click)="listSkpdModal.hide()">
                    <i class="fa fa-close"></i> Tutup
                </button>&nbsp;
                <button class="btn btn-primary" (click)="pilihSkpd()">
                    <i class="fa fa-check"></i> Pilih SKPD
                </button>
            </div>
        </div>

    </div>
</div>