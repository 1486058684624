import { Location } from '@angular/common';
import {AfterViewInit, Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { OpdModel } from 'src/app/_model/opd.model';
import { GlobalService } from 'src/app/_services/global.service';
import * as FileSaver from 'file-saver';
import { Subject } from 'rxjs';
import {
    BuktiPotonganPajakModel,
    DatatablesSpdHomeDokumenRequest,
    DatatablesSpdHomeDokumenResponse,
    Sp2dDatatablesCetak,
    Sp2dPencetakanDatatables,
    SpmPencetakanDatatables,
    SppCetakModel,
} from 'src/app/_model/document-download.model';
import { DocumentDownloadService } from 'src/app/_services/document-download.service';
import { FormatRupiah } from 'src/app/_services/format-rupiah';
import { Router } from '@angular/router';
import { CsvModel } from 'src/app/_model/csv.model';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-document-download',
    templateUrl: './document-download.component.html',
    styleUrls: ['./document-download.component.scss'],
    providers: [NgbModalConfig, NgbModal]
})
export class DocumentDownloadComponent implements OnInit, AfterViewInit {

    formatRupiah: FormatRupiah = new FormatRupiah();
    tahunAnggaran = JSON.parse(localStorage.getItem('tahunAnggaran'));
    submittedDownload = false;

    formDownload: FormGroup;
    formSearchSkpdOpd: FormGroup;
    formSearchSpd: FormGroup;
    formSearchSp2d: FormGroup;
    formSearchBuktiBayarPajak: FormGroup;
    formSearchTandaTerimaSpm: FormGroup;
    formSearchSpm: FormGroup;
    formSearchSpp: FormGroup;

    @ViewChildren(DataTableDirective)
    dtElements: QueryList<DataTableDirective>;
    dtOptions: DataTables.Settings[] = [];
    dtTriggers: Subject<any>[] = [
        new Subject(),
        new Subject(),
        new Subject(),
        new Subject(),
        new Subject(),
        new Subject(),
        new Subject()
    ];

    listModule = ['SPD', 'SP2D', 'Bukti Bayar Pajak', 'BKU Realisasi RSK', 'Generate CSV','Potongan BPJS Kesehatan (Non ASN) 1%','Potongan BPJS Kesehatan (Non ASN) 4%', 'Tanda Terima SPM', 'SPM', 'SPP', 'Laporan Pembayaran BPJS Kesehatan'];

    @ViewChild('listSkpdOpdModal', { static: false })
    listSkpdOpdModal: any;

    @ViewChild('listSpdModal', { static: false })
    listSpdModal: any;

    @ViewChild('listSp2dModal', { static: false })
    listSp2dModal: any;

    @ViewChild('listBuktiBayarPajakModal', { static: false })
    listBuktiBayarPajakModal: any;

    @ViewChild('listTandaTerimaSpmModal', { static: false })
    listTandaTerimaSpmModal: any;

    @ViewChild('listSpmModal', { static: false })
    listSpmModal: any;

    @ViewChild('listSppModal', { static: false })
    listSppModal: any;

    skpdOpdSelectedTemp: OpdModel = new OpdModel();
    spdSelectedTemp: DatatablesSpdHomeDokumenResponse = new DatatablesSpdHomeDokumenResponse();
    sp2dSelectedTemp: Sp2dDatatablesCetak = new Sp2dDatatablesCetak();
    buktiBayarPajakSelectedTemp: BuktiPotonganPajakModel = new BuktiPotonganPajakModel();
    tandaTerimaSpmSelectedTemp: Sp2dPencetakanDatatables = new Sp2dPencetakanDatatables();
    spmSelectedTemp: SpmPencetakanDatatables = new SpmPencetakanDatatables();
    sppSelectedTemp: SppCetakModel = new SppCetakModel();

    idSpp = '';
    // Request Param Bukti Bayar Pajak
    idSpmPot = '';
    idRequest = '';

    // Keperluan Download CSV
    formParamCsv: FormGroup;
    listCsv: CsvModel[] = [];
    selectedCsv: CsvModel = new CsvModel();
    fileSelectedCsv = false;

    constructor(
        // tslint:disable-next-line:variable-name
        private _formBuilder: FormBuilder,
        private _location: Location,
        private _globalService: GlobalService,
        private _service: DocumentDownloadService,
        private _toastr: ToastrService,
        private _route: Router,
        private _spinner: NgxSpinnerService
    ) {
    }

    ngOnInit(): void {

        this.initDataTablesSkpdOpd();
        this.initDataTablesSpd();
        this.initDataTablesSp2d();
        this.initDataTablesBuktiBayarPajak();
        this.initDataTablesTandaTerimaSpm();
        this.initDataTablesSpm();
        this.initDataTablesSpp();

        this.formDownload = this._formBuilder.group({
            tahunAnggaran: this._formBuilder.control(this.tahunAnggaran),
            module: this._formBuilder.control('SPD'),
            idOpd: this._formBuilder.control(null),
            namaOpd: this._formBuilder.control(null),
            nomor: this._formBuilder.control(null),
            tanggalAwal: this._formBuilder.control(''),
            tanggalAkhir: this._formBuilder.control(''),
        });

        const tahunAnggaran = localStorage.getItem('tahunAnggaran');
        this.formParamCsv = this._formBuilder.group({
            id: this._formBuilder.control(null),
            namaTable: this._formBuilder.control(null),
            namaFileCsv: this._formBuilder.control(null),
            query: this._formBuilder.control(null),
            query2: this._formBuilder.control(null),
            query3: this._formBuilder.control(null),
            kodeParamTahun: this._formBuilder.control(null),
            kodeParamBulan: this._formBuilder.control(null),
            kodeParamTglAwal: this._formBuilder.control(null),
            kodeParamTglAkhir: this._formBuilder.control(null),
            kodeParamOpd: this._formBuilder.control(null),
            kodeParamWilSp2d: this._formBuilder.control(null),
            kodeParamWilSpd: this._formBuilder.control(null),
            kodeParamX1: this._formBuilder.control(null),
            kodeParamX2: this._formBuilder.control(null),
            kodeParamX3: this._formBuilder.control(null),
            kodeParamX4: this._formBuilder.control(null),
            kodeParamX5: this._formBuilder.control(null),
            paramTahun: this._formBuilder.control(tahunAnggaran),
            paramBulan: this._formBuilder.control(null),
            paramTglAwal: this._formBuilder.control(null),
            paramTglAkhir: this._formBuilder.control(null),
            paramOpd: this._formBuilder.control(null),
            paramWilSp2d: this._formBuilder.control(null),
            paramWilSpd: this._formBuilder.control(null),
            paramX1: this._formBuilder.control(null),
            paramX2: this._formBuilder.control(null),
            paramX3: this._formBuilder.control(null),
            paramX4: this._formBuilder.control(null),
            paramX5: this._formBuilder.control(null),
            kodeNamaOpd: this._formBuilder.control(null)
        });
        this.getList();
    }

    goBack() {
        this._location.back();
    }

    initDataTablesSkpdOpd() {
        this.formSearchSkpdOpd = this._formBuilder.group({
            kodeOpd: this._formBuilder.control(null),
            namaOpd: this._formBuilder.control(null),
        });
        this.dtOptions[0] = {
            lengthMenu: [[10, 25, 50, 100, -1], [10, 25, 50, 100, 'All']],
            pagingType: 'full_numbers',
            serverSide: true,
            searching: false,
            processing: true,
            autoWidth: false,
            dom: 't<\'row\'<\'col-sm-12\'ip><\'col-sm-12\'l>>',
            order: [1, 'asc'],
            ajax: (dataTablesParameters: any, callback) => {
                const search: OpdModel = this.formSearchSkpdOpd.value;
                this._globalService.datatableOpdByPengguna(dataTablesParameters, search).subscribe(resp => {
                    callback({
                        recordsTotal: resp.recordsTotal,
                        recordsFiltered: resp.recordsFiltered,
                        data: resp.data
                    });
                }, error => {
                    callback({
                        recordsTotal: 0,
                        recordsFiltered: 0,
                        data: []
                    });
                });
            },
            columns: [
                {
                    width: '10%',
                    data: null,
                    title: 'Pilih',
                    orderable: false,
                    className: 'text-center align-middle nopadding',
                    render: (data: any, type: any, row: any, meta) => {
                        return `<input type="radio" id="checkItemOpd" name="checkItemOpd"/>`;
                    }
                },
                { width: '25%', data: 'kodeOpd', title: 'Kode', className: 'align-middle nopadding text-center' },
                { width: '55%', data: 'namaOpd', title: 'Nama', className: 'align-middle nopadding' },
            ],
            rowCallback: (row: Node, data: OpdModel, index: number) => {
                $('input#checkItemOpd', row).click(() => {
                    console.log(data);
                    this.skpdOpdSelectedTemp = data;
                });
                return row;
            }
        };
    }

    initDataTablesSpd() {
        this.formSearchSpd = this._formBuilder.group({
            tahunAnggaran: this._formBuilder.control(this.tahunAnggaran),
            idOpd: this._formBuilder.control(null),
            noSpd: this._formBuilder.control(null),
            status: this._formBuilder.control(null),
            pegawaiTtd: this._formBuilder.control(null)
        });
        this.dtOptions[1] = {
            lengthMenu: [[10, 25, 50, 100, -1], [10, 25, 50, 100, 'All']],
            pagingType: 'full_numbers',
            serverSide: true,
            searching: false,
            processing: true,
            autoWidth: false,
            dom: 't<\'row\'<\'col-sm-12\'ip><\'col-sm-12\'l>>',
            order: [1, 'asc'],
            ajax: (dataTablesParameters: any, callback) => {
                const search: DatatablesSpdHomeDokumenRequest = this.formSearchSpd.value;
                const idOpd = this.formDownload.value.idOpd;
                console.log(idOpd);
                this._service.datatablesSpdHomeDokumen(dataTablesParameters, search).subscribe(resp => {
                    callback({
                        recordsTotal: resp.recordsTotal,
                        recordsFiltered: resp.recordsFiltered,
                        data: resp.data
                    });
                }, error => {
                    callback({
                        recordsTotal: 0,
                        recordsFiltered: 0,
                        data: []
                    });
                });
            },
            columns: [
                {
                    width: '10%',
                    data: null,
                    title: 'Pilih',
                    orderable: false,
                    className: 'text-center align-middle nopadding',
                    render: (data: any, type: any, row: any, meta) => {
                        return `<input type="radio" id="checkItemSpd" name="checkItemSpd"/>`;
                    }
                },
                { width: '15%', data: 'noSpd', title: 'No. SPD', className: 'nopadding align-middle' },
                { width: '20%', data: 'jenis', title: 'Jenis', className: 'nopadding align-middle' },
                { width: '20%', data: 'tanggalSpd', title: 'Tgl. SPD', className: 'text-center align-middle nopadding' },
                {
                    width: '30%',
                    data: 'nilaiSpd',
                    title: 'Nilai SPD',
                    className: 'text-center align-middle nopadding',
                    render: (data: any, type: any, row: any, meta) => {
                        return `<div class="justify-content-end text-right">
                                    ${this.formatRupiah.format(data ? data : 0, 'Rp. ')}
                                </div>`;
                    }
                },
                { width: '20%', data: 'statusSpd', title: 'Status SPD', className: 'text-center align-middle nopadding' },
                {
                    width: '20%',
                    data: 'tanggalCetak',
                    title: 'Tgl. Cetak',
                    className: 'text-center align-middle nopadding',
                    render: (data: any, type: any, row: any, meta) => {
                        if (data === null) {
                            return `-`;
                        } else {
                            return `${data}`;
                        }
                    }
                },
                { width: '20%', data: 'pegawaiTtd', title: 'Pegawai TTD', className: 'align-middle nopadding' },
            ],
            rowCallback: (row: Node, data: DatatablesSpdHomeDokumenResponse, index: number) => {
                $('input#checkItemSpd', row).click(() => {
                    this.spdSelectedTemp = data;
                });
                return row;
            }
        };
    }

    initDataTablesSp2d() {
        this.formSearchSp2d = this._formBuilder.group({
            tanggalSp2dAwal: this._formBuilder.control(''),
            tanggalSp2dAkhir: this._formBuilder.control(''),
            noSp2d: this._formBuilder.control(''),
            jenis: this._formBuilder.control(''),
            status: this._formBuilder.control('VALIDASI')
        });
        this.dtOptions[2] = {
            lengthMenu: [[10, 25, 50, 100, -1], [10, 25, 50, 100, 'All']],
            pagingType: 'full_numbers',
            serverSide: true,
            searching: false,
            processing: true,
            autoWidth: false,
            dom: 't<\'row\'<\'col-sm-12\'ip><\'col-sm-12\'l>>',
            order: [1, 'asc'],
            ajax: (dataTablesParameters: any, callback) => {
                let search: Sp2dDatatablesCetak = this.formSearchSp2d.value;
                search = this._service.filterJenis(search);
                const idOpd = this.formDownload.value.idOpd;
                this._service.datatablesSp2dCetak(dataTablesParameters, search, idOpd ? idOpd.toString() : '-1').subscribe(resp => {
                    callback({
                        recordsTotal: resp.recordsTotal,
                        recordsFiltered: resp.recordsFiltered,
                        data: resp.data
                    });
                }, error => {
                    callback({
                        recordsTotal: 0,
                        recordsFiltered: 0,
                        data: []
                    });
                });
            },
            columns: [
                {
                    width: '10%',
                    data: null,
                    title: 'Pilih',
                    orderable: false,
                    className: 'text-center align-middle nopadding',
                    render: (data: any, type: any, row: any, meta) => {
                        return `<input type="radio" id="checkItemSp2d" name="checkItemSp2d"/>`;
                    }
                },
                { width: '10%', data: 'noSp2d', title: 'No. SP2D', className: 'align-middle nopadding text-center' },
                { width: '10%', data: 'tanggalSp2d', title: 'Tanggal SP2D', className: 'align-middle nopadding text-center' },
                { width: '10%', data: 'kodeBeban', title: 'Kode Beban', className: 'align-middle nopadding text-center' },
                { width: '10%', data: 'namaJenis', title: 'Kode Jenis', className: 'align-middle nopadding text-center' },
                {
                    width: '20%',
                    data: 'nilaiSp2d',
                    title: 'Nilai SP2D',
                    className: 'align-middle nopadding text-right',
                    render: $.fn.dataTable.render.number(',', '.', 2, 'Rp ')
                },
                { width: '10%', data: 'status', title: 'Status', className: 'align-middle nopadding text-center' },
                { width: '10%', data: 'namaKbud', title: 'Nama KBUD', className: 'align-middle nopadding' }
            ],
            rowCallback: (row: Node, data: Sp2dDatatablesCetak, index: number) => {
                $('input#checkItemSp2d', row).click(() => {
                    this.sp2dSelectedTemp = data;
                });
                return row;
            }
        };
    }

    initDataTablesBuktiBayarPajak() {
        this.formSearchBuktiBayarPajak = this._formBuilder.group({
            noSp2d: this._formBuilder.control(null),
            uraianPajak: this._formBuilder.control(null)
        });
        this.dtOptions[3] = {
            lengthMenu: [[10, 25, 50, 100, -1], [10, 25, 50, 100, 'All']],
            pagingType: 'full_numbers',
            serverSide: true,
            searching: false,
            processing: true,
            autoWidth: false,
            dom: 't<\'row\'<\'col-sm-12\'ip><\'col-sm-12\'l>>',
            order: [1, 'desc'],
            ajax: (dataTablesParameters: any, callback) => {
                const search: BuktiPotonganPajakModel = this.formSearchBuktiBayarPajak.value;
                const idOpd = this.formDownload.value.idOpd;
                this._service.datatablesBuktiBayarPajak(dataTablesParameters, search, idOpd ? idOpd : -1).subscribe(resp => {

                    callback({
                        recordsTotal: resp.recordsTotal,
                        recordsFiltered: resp.recordsFiltered,
                        data: resp.data
                    });
                }, error => {
                    callback({
                        recordsTotal: 0,
                        recordsFiltered: 0,
                        data: []
                    });
                });
            },
            columns: [
                {
                    width: '10%',
                    data: null,
                    title: 'Pilih',
                    orderable: false,
                    className: 'text-center align-middle nopadding',
                    render: (data: any, type: any, row: any, meta) => {
                        return `<input type="radio" id="checkItemBuktiBayarPajak" name="checkItemBuktiBayarPajak"/>`;
                    }
                },
                { width: '20%', data: 'noSp2d', title: 'No. SP2D', className: 'align-middle nopadding' },
                { width: '25%', data: 'noSp2dFormat', title: 'No. SP2D Dok', className: 'align-middle nopadding' },
                { width: '20%', data: 'uraianPajak', title: 'Jenis Pajak', className: 'align-middle nopadding' },
                {
                    width: '40%',
                    data: 'nilaiPajak',
                    title: 'Nilai SP2D',
                    className: 'align-middle nopadding',
                    render: $.fn.dataTable.render.number(',', '.', 2, 'Rp ')
                },
                { width: '25%', data: 'kodeBilling', title: 'Kode Billing', className: 'align-middle nopadding' },
                { width: '25%', data: 'ntpn', title: 'No. NTPN Billing', className: 'align-middle nopadding' }
            ],
            rowCallback: (row: Node, data: BuktiPotonganPajakModel, index: number) => {
                $('input#checkItemBuktiBayarPajak', row).click(() => {
                    this.buktiBayarPajakSelectedTemp = data;
                });
                return row;
            }
        };
    }

    initDataTablesTandaTerimaSpm() {
        this.formSearchTandaTerimaSpm = this._formBuilder.group({
            tanggalSp2dAwal: this._formBuilder.control(''),
            tanggalSp2dAkhir: this._formBuilder.control(''),
            noSp2d: this._formBuilder.control(''),
            jenis: this._formBuilder.control(''),
        });
        this.dtOptions[4] = {
            lengthMenu: [[10, 25, 50, 100, -1], [10, 25, 50, 100, 'All']],
            pagingType: 'full_numbers',
            serverSide: true,
            searching: false,
            processing: true,
            autoWidth: false,
            dom: 't<\'row\'<\'col-sm-12\'ip><\'col-sm-12\'l>>',
            order: [1, 'asc'],
            ajax: (dataTablesParameters: any, callback) => {
                let search: Sp2dPencetakanDatatables = this.formSearchTandaTerimaSpm.value;
                search = this._service.filterJenis(search);
                const idOpd = this.formDownload.value.idOpd;
                this._service.datatablePencetakan(dataTablesParameters, search, idOpd ? idOpd.toString() : '-1').subscribe(resp => {
                    callback({
                        recordsTotal: resp.recordsTotal,
                        recordsFiltered: resp.recordsFiltered,
                        data: resp.data
                    });
                }, error => {
                    callback({
                        recordsTotal: 0,
                        recordsFiltered: 0,
                        data: []
                    });
                });
            },
            columns: [
                {
                    width: '10%',
                    data: null,
                    title: 'Pilih',
                    orderable: false,
                    className: 'text-center align-middle nopadding',
                    render: (data: any, type: any, row: any, meta) => {
                        return `<input type="radio" id="checkItemTandaTerimaSpm" name="checkItemTandaTerimaSpm"/>`;
                    }
                },
                {width: '10%', data: 'noSp2d', title: 'No. SP2D', className: 'align-middle nopadding'},
                {width: '10%', data: 'tanggalSp2d', title: 'Tanggal SP2D', className: 'align-middle nopadding'},
                {width: '10%', data: 'kodeBeban', title: 'Kode Beban', className: 'align-middle nopadding'},
                {width: '10%', data: 'namaJenis', title: 'Kode Jenis', className: 'align-middle nopadding'},
                {
                    width: '20%',
                    data: 'nilaiSp2d',
                    title: 'Nilai SP2D',
                    className: 'align-middle nopadding',
                    render: $.fn.dataTable.render.number(',', '.', 2, 'Rp ')
                },
                {width: '10%', data: 'status', title: 'Status', className: 'align-middle nopadding'},
                {width: '10%', data: 'namaKbud', title: 'Nama KBUD', className: 'align-middle nopadding'}
            ],
            rowCallback: (row: Node, data: Sp2dPencetakanDatatables, index: number) => {
                $('input#checkItemTandaTerimaSpm', row).click(() => {
                    this.tandaTerimaSpmSelectedTemp = data;
                });
                return row;
            }
        };

    }

    initDataTablesSpm() {
        this.formSearchSpm = this._formBuilder.group({
            noSpp: this._formBuilder.control(null),
            noSpm: this._formBuilder.control(null),
            namaPptk: this._formBuilder.control(null),
            kodeBeban: this._formBuilder.control(null),
            kodeJenis: this._formBuilder.control(''),
            status: this._formBuilder.control(''),
            nilaiSpm: this._formBuilder.control(null),
            jenisSpp: this._formBuilder.control(''),
            jenis: this._formBuilder.control('')
        });
        this.dtOptions[5] = {
            lengthMenu: [[10, 25, 50, 100, -1], [10, 25, 50, 100, 'All']],
            pagingType: 'full_numbers',
            serverSide: true,
            searching: false,
            processing: true,
            autoWidth: false,
            dom: 't<\'row\'<\'col-sm-12\'ip><\'col-sm-12\'l>>',
            order: [1, 'asc'],
            ajax: (dataTablesParameters: any, callback) => {
                let search: SpmPencetakanDatatables = this.formSearchSpm.value;
                search = this._service.filterJenis(search);
                const idOpd = this.formDownload.value.idOpd;
                this._service.datatablesSpm(dataTablesParameters, search, idOpd ? idOpd.toString() : '-1').subscribe(resp => {
                    callback({
                        recordsTotal: resp.recordsTotal,
                        recordsFiltered: resp.recordsFiltered,
                        data: resp.data
                    });
                }, error => {
                    callback({
                        recordsTotal: 0,
                        recordsFiltered: 0,
                        data: []
                    });
                });
            },
            columns: [
                {
                    width: '10%',
                    data: null,
                    title: 'Pilih',
                    orderable: false,
                    className: 'text-center align-middle nopadding',
                    render: (data: any, type: any, row: any, meta) => {
                        return `<input type="radio" id="checkItemSpm" name="checkItemSpm"/>`;
                    }
                },
                {width: '10%', data: 'noSpp', title: 'Nomor SPP', className: 'align-middle nopadding text-center'},
                {width: '10%', data: 'noSpm', title: 'Nomor SPM', className: 'align-middle nopadding text-center'},
                {width: '15%', data: 'jenisSpp', title: 'Jenis SPM', className: 'align-middle nopadding text-center'},
                {
                    width: '20%',
                    data: null,
                    title: 'Nilai SPM',
                    className: 'align-middle nopadding text-right',
                    render: (data: SpmPencetakanDatatables) => {
                        return this.formatRupiah.format(data.nilaiSpm, '');
                    }
                },
                {width: '10%', data: 'status', title: 'Status', className: 'align-middle nopadding text-center'},
                {width: '15%', data: 'namaBendahara', title: 'Nama Bendahara', className: 'align-middle nopadding text-center'}
 
            ],
            rowCallback: (row: Node, data: SpmPencetakanDatatables, index: number) => {
                $('input#checkItemSpm', row).click(() => {
                    this.spmSelectedTemp = data;
                });
                return row;
            }
        };
    }
    
    initDataTablesSpp() {
        this.formSearchSpp = this._formBuilder.group({
            noSpp: this._formBuilder.control(null),
            tanggalSppUnformated: this._formBuilder.control(null),
            cariTanggalSppMulai: this._formBuilder.control(null),
            cariTanggalSppAkhir: this._formBuilder.control(null),
            statusGlobal: this._formBuilder.control(''),
            jenisSpp: this._formBuilder.control('')
        });
        this.dtOptions[6] = {
            lengthMenu: [[10, 25, 50, 100, -1], [10, 25, 50, 100, 'All']],
            pagingType: 'full_numbers',
            serverSide: true,
            searching: false,
            processing: true,
            autoWidth: false,
            dom: 't<\'row\'<\'col-sm-12\'ip><\'col-sm-12\'l>>',
            order: [1, 'asc'],
            ajax: (dataTablesParameters: any, callback) => {
                let search: SppCetakModel = this.formSearchSpp.value;
                search = this._service.filterJenis(search);
                const idOpd = this.formDownload.value.idOpd;
                const tahunAnggaran = this.formDownload.value.tahunAnggaran;
                this._service.datatablesSpp(dataTablesParameters, search, idOpd ? idOpd.toString() : '-1', tahunAnggaran).subscribe(resp => {
                    callback({
                        recordsTotal: resp.recordsTotal,
                        recordsFiltered: resp.recordsFiltered,
                        data: resp.data
                    });
                }, error => {
                    callback({
                        recordsTotal: 0,
                        recordsFiltered: 0,
                        data: []
                    });
                });
            },
            columns: [
                {
                    width: '10%',
                    data: null,
                    title: 'Pilih',
                    orderable: false,
                    className: 'text-center align-middle nopadding',
                    render: (data: any, type: any, row: any, meta) => {
                        return `<input type="radio" id="checkItemSpp" name="checkItemSpp"/>`;
                    }
                },
                {width: '10%', data: 'noSpp', title: 'NO. SPP', className: 'text-center align-middle nopadding'},
                {width: '13%', data: 'jenisSpp', title: 'JENIS', className: 'text-center align-middle nopadding'},
                {
                    width: '10%',
                    data: 'tanggalSpp',
                    title: 'TANGGAL SPP',
                    className: 'text-center align-middle nopadding',
                },
                {
                    width: '15%',
                    data: 'nilaiSpp',
                    title: 'NILAI SPP',
                    className: 'text-center align-middle nopadding',
                    render: (data: any, type: any, row: any, meta) => {
                        return `<div class="justify-content-end text-right">
                        ${this.formatRupiah.format(data ? data : 0, 'Rp. ')}
                    </div>`;
                    }
                },
                {
                    width: '15%',
                    data: 'nilaiRsk',
                    title: 'NILAI RSK',
                    className: 'text-center align-middle nopadding',
                    render: (data: any, type: any, row: any, meta) => {
                        return `<div class="justify-content-end text-right">
                                    ${this.formatRupiah.format(data ? data : 0, 'Rp. ')}
                                </div>`;
                    }
                },
                {
                    width: '12%',
                    data: 'statusGlobal',
                    title: 'STATUS GLOBAL',
                    className: 'text-center align-middle nopadding'
                }
            ],
            rowCallback: (row: Node, data: SppCetakModel, index: number) => {
                $('input#checkItemSpp', row).click(() => {
                    this.sppSelectedTemp = data;
                });
                return row;
            }
        };
    }

    refresh(idx: number) {
        console.log('search index ' + idx);
        const dtElementsArray = this.dtElements.toArray();
        dtElementsArray[idx].dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
        });
        this.dtTriggers[idx].next();
    }

    ngAfterViewInit(): void {
        for (const dtTrigger of this.dtTriggers) {
            dtTrigger.next();
        }
    }

    moduleChange() {
        console.log('Module Change');
        console.log(this.formDownload.value.module);
        switch (this.formDownload.value.module) {
            case 'SPD':
                this.refresh(1);
                break;
            case 'SP2D':
                this.refresh(2);
                break;
            case 'Bukti Bayar Pajak':
                this.refresh(3);
                break;
            case 'Tanda Terima SPM':
                this.refresh(4);
                break;
            case 'SPM':
                this.refresh(5);
                break;
            case 'SPP':
                this.refresh(6);
                break;
        }
    }

    selectedSkpdOpd() {
        this.formDownload.patchValue({
            idOpd: this.skpdOpdSelectedTemp.id,
            namaOpd: this.skpdOpdSelectedTemp.namaOpd
        });
        this.formSearchSpd.patchValue({
            idOpd: this.skpdOpdSelectedTemp.id
        });
        this.moduleChange();
        this.formParamCsv.patchValue({
            kodeNamaOpd: this.skpdOpdSelectedTemp?.kodeNamaOpd,
            paramOpd: this.skpdOpdSelectedTemp?.kodeOpd,
            paramWilSp2d: this.skpdOpdSelectedTemp?.kodeWilSp2dProses,
            paramWilSpd: this.skpdOpdSelectedTemp?.kodeWilSpdProses
        });
        this.listSkpdOpdModal.hide();
    }

    selectedSpd() {
        this.formDownload.patchValue({
            nomor: this.spdSelectedTemp.noSpd
        });
        this.listSpdModal.hide();
    }

    selectedSp2d() {
        this.formDownload.patchValue({
            nomor: this.sp2dSelectedTemp.noSp2d
        });
        this.listSp2dModal.hide();
    }

    selectedBuktiBayarPajak() {
        this.formDownload.patchValue({
            nomor: `ID SPM POT : ${this.buktiBayarPajakSelectedTemp.idSpmPot}, ID Request : ${this.buktiBayarPajakSelectedTemp.idRequest}`
        });
        this.idSpmPot = this.buktiBayarPajakSelectedTemp.idSpmPot;
        this.idRequest = this.buktiBayarPajakSelectedTemp.idRequest;
        this.listBuktiBayarPajakModal.hide();
    }

    selectedTandaTerimaSpm() {
        this.formDownload.patchValue({
            nomor: this.tandaTerimaSpmSelectedTemp.noSpm
        });
        this.idSpp = this.tandaTerimaSpmSelectedTemp.idSpp;
        this.listTandaTerimaSpmModal.hide();
    }

    selectedSpm() {
        this.formDownload.patchValue({
            nomor: this.spmSelectedTemp.noSpm
        });
        this.listSpmModal.hide();
    }

    selectedSpp() {
        this.formDownload.patchValue({
            nomor: this.sppSelectedTemp.noSpp
        });
        this.listSppModal.hide();
    }

    clearDate() {
        this.formSearchTandaTerimaSpm.patchValue({
            tanggalSpmCetak: null
        });
    }

    deleteDate() {
        this.formSearchSpp.patchValue({
            tanggalSppUnformated: null
        });
    }

    clearSp2dDate1() {
        this.formSearchTandaTerimaSpm.patchValue({
            tanggalSp2dAwal: '',
            tanggalSp2dAkhir: ''
        });
    }

    clearSp2dDate2() {
        this.formSearchTandaTerimaSpm.patchValue({
            tanggalSp2dAkhir: ''
        });
    }

    getMaxMinDate(event) {
        //
        // this.changeDetector.detectChanges();
        return event._model;
    }

    download() {
        this.submittedDownload = true;
        const value = this.formDownload.value;
        console.log('Form Download Document');
        console.log(this.formDownload.value);
        this._spinner.show('downloadDoc');
        if (this.formDownload.value.module === 'Bukti Bayar Pajak') {
            this._service.documentDownloadBuktiBayarPajak(this.idSpmPot, this.idRequest).subscribe((response) => {
                if (response.status === 200) {
                    FileSaver.saveAs(
                        response.body,
                        `bukti-potongan-pajak.pdf`
                    );
                } else {
                    this._toastr.warning('Bukti Potongan Pajak gagal unduh PDF, Silahkan Coba Lagi', 'Kesalahan Server');
                }
                this._spinner.hide('downloadDoc');
                this.submittedDownload = false;
            }, error => {
                this._spinner.hide('downloadDoc');
                this.submittedDownload = false;
                this._toastr.error('Bukti Potongan Pajak gagal unduh PDF, Silahkan Coba Lagi', 'Kesalahan Server');
            });
        } else if (this.formDownload.value.module === 'BKU Realisasi RSK') {
            this._service.documentDownloadBkuRealisasiRsk(this.tahunAnggaran, this.formDownload.value.idOpd).subscribe((response) => {
                if (response.status === 200) {
                    FileSaver.saveAs(
                        response.body,
                        `bku_realisasi_rsk.xlsx`
                    );
                } else {
                    this._toastr.warning('BKU Realisasi RSK gagal unduh PDF, Silahkan Coba Lagi', 'Kesalahan Server');
                }
                this._spinner.hide('downloadDoc');
                this.submittedDownload = false;
            }, error => {
                this._spinner.hide('downloadDoc');
                this.submittedDownload = false;
                this._toastr.error('BKU Realisasi RSK gagal unduh PDF, Silahkan Coba Lagi', 'Kesalahan Server');
            });
        }else if(this.formDownload.value.module === 'Potongan BPJS Kesehatan (Non ASN) 1%'){
            this._service.documentBpjsKesehatanNonAsnSatuPersen(this.tahunAnggaran, this.formDownload.value.idOpd).subscribe((response) => {
                if (response.status === 200) {
                    FileSaver.saveAs(
                        response.body,
                        `bpjs_kesehatan_non_asn_satu_persen.xlsx`
                    );
                } else {
                    this._toastr.warning('BPotongan BPJS Kesehatan (Non ASN) 1% gagal unduh PDF, Silahkan Coba Lagi', 'Kesalahan Server');
                }
                this._spinner.hide('downloadDoc');
                this.submittedDownload = false;
            }, error => {
                this._spinner.hide('downloadDoc');
                this.submittedDownload = false;
                this._toastr.error('Potongan BPJS Kesehatan (Non ASN) 1% gagal unduh PDF, Silahkan Coba Lagi', 'Kesalahan Server');
            });
        }else if(this.formDownload.value.module === 'Potongan BPJS Kesehatan (Non ASN) 4%'){
            this._service.documentBpjsKesehatanNonAsnEmpatPersen(this.tahunAnggaran, this.formDownload.value.idOpd).subscribe((response) => {
                if (response.status === 200) {
                    FileSaver.saveAs(
                        response.body,
                        `bpjs_kesehatan_non_asn_empat_persen.xlsx`
                    );
                } else {
                    this._toastr.warning('Potongan BPJS Kesehatan (Non ASN) 4% gagal unduh PDF, Silahkan Coba Lagi', 'Kesalahan Server');
                }
                this._spinner.hide('downloadDoc');
                this.submittedDownload = false;
            }, error => {
                this._spinner.hide('downloadDoc');
                this.submittedDownload = false;
                this._toastr.error('Potongan BPJS Kesehatan (Non ASN) 4% gagal unduh PDF, Silahkan Coba Lagi', 'Kesalahan Server');
            });
        }else if(this.formDownload.value.module === 'Tanda Terima SPM'){
            this._service.documentDownloadPdfTandaTerima(this.formSearchTandaTerimaSpm.value.idSpm, this.tahunAnggaran).subscribe((response) => {
                if (response.status === 200) {
                    FileSaver.saveAs(
                        response.body,
                        `${value.tahunAnggaran}-${value.nomor}-${value.idSpm}.pdf`
                    );
                } else {
                    this._toastr.warning('Tanda Terima SPM gagal unduh PDF, Silahkan Coba Lagi', 'Kesalahan Server');
                }
                this._spinner.hide('downloadDoc');
                this.submittedDownload = false;
            }, error => {
                this._spinner.hide('downloadDoc');
                this.submittedDownload = false;
                this._toastr.error('Tanda Terima SPM gagal unduh PDF, Silahkan Coba Lagi', 'Kesalahan Server');
            });
        }else if (this.formDownload.value.module === 'Laporan Pembayaran BPJS Kesehatan') {
            let tanggalAwal = this.convertTanggal(this.formDownload.value.tanggalAwal);
            let tanggalAkhir = this.convertTanggal(this.formDownload.value.tanggalAkhir);
            this._service.documentDownloadPdfLaporanPembayaranBpjsKesehatan(this.formDownload.value.idOpd, this.tahunAnggaran,
                tanggalAwal,tanggalAkhir).subscribe((response) => {
                if (response.status === 200) {
                    FileSaver.saveAs(
                        response.body,
                        `laporan_pembayaran_bpjs_kesehatan.pdf`
                    );
                } else {
                    this._toastr.warning('Laporan Pembayaran BPJS Kesehatan unduh PDF, Silahkan Coba Lagi', 'Kesalahan Server');
                }
                this._spinner.hide('downloadDoc');
                this.submittedDownload = false;
            }, error => {
                this._spinner.hide('downloadDoc');
                this.submittedDownload = false;
                this._toastr.error('Laporan Pembayaran BPJS Kesehatan gagal unduh PDF, Silahkan Coba Lagi', 'Kesalahan Server');
            });
        }else {
            this._service.documentDownloadSpdSp2d(this.formDownload.value).subscribe((response) => {
                if (response.status === 200) {
                    this._toastr.success('Berhasil Download Dokumen');
                    FileSaver.saveAs(
                        response.body,
                        `${value.module}-${value.tahunAnggaran}-${value.nomor}-${value.idOpd}.pdf`
                    );
                }
                this._spinner.hide('downloadDoc');
                this.submittedDownload = false;
            }, error => {
                this._spinner.hide('downloadDoc');
                this.submittedDownload = false;
                this._toastr.error('Dokumen Tidak Ditemukan');
            });
        }

    }

    // Keperluan CSV
    getList() {
        this._globalService.listCsv().subscribe((resp: any) => {
            this.listCsv = resp.body;
        });
    }

    generateCsv() {
        this._spinner.show('downloadDoc');
        this._globalService.generateCsv(this.formParamCsv.value).subscribe((resp: any) => {
            console.log(resp);
            FileSaver.saveAs(resp.body, this.selectedCsv.namaFileCsv);
            this._spinner.hide('downloadDoc');
        });
    }

    pilihCsv(event) {
        if (event.target.value === 'null') {
            this.fileSelectedCsv = false;
        } else {
            this.fileSelectedCsv = true;
            // this.tableNameOfSelectedCsv = ;
            this.selectedCsv = this.listCsv.find(csv => csv.namaTable === event.target.value);
            this.formParamCsv.patchValue({
                namaFileCsv: this.selectedCsv.namaFileCsv,
                namaTable: this.selectedCsv.namaTable,
                keteranganTable: this.selectedCsv.keteranganTable,
                query: this.selectedCsv.query,
                query2: this.selectedCsv.query2,
                query3: this.selectedCsv.query3,
                kodeAktif: this.selectedCsv.kodeAktif,
                id: this.selectedCsv.id,
                kodeParamTahun: this.selectedCsv.kodeParamTahun,
                kodeParamBulan: this.selectedCsv.kodeParamBulan,
                kodeParamTglAwal: this.selectedCsv.kodeParamTglAwal,
                kodeParamTglAkhir: this.selectedCsv.kodeParamTglAkhir,
                kodeParamOpd: this.selectedCsv.kodeParamOpd,
                kodeParamWilSp2d: this.selectedCsv.kodeParamWilSp2d,
                kodeParamWilSpd: this.selectedCsv.kodeParamWilSpd,
                kodeParamX1: this.selectedCsv.kodeParamX1,
                kodeParamX2: this.selectedCsv.kodeParamX2,
                kodeParamX3: this.selectedCsv.kodeParamX3,
                kodeParamX4: this.selectedCsv.kodeParamX4,
                kodeParamX5: this.selectedCsv.kodeParamX5
            });
        }
    }


    convertTanggal(value) {
        console.log(value);
        if ((value == '') || (value == null)) {
          return '';
        } else {
          let tanggal = value;
          if (tanggal.month == undefined) {
            return tanggal;
          }
          const tmonth: string = tanggal.month;
          const finalBulan = tmonth.toString().length == 1 ? `0${tanggal.month}` : tanggal.month;
          const tTanggal: string = tanggal.day;
          const finalTanggal = tTanggal.toString().length == 1 ? `0${tanggal.day}` : tanggal.day;
          console.log(tmonth.length);
          let finDate = tanggal.year + '-' + finalBulan + '-' + finalTanggal;
          return finDate;
        }
      }
}
