import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { OpdModel } from 'src/app/_model/opd.model';
import { HelpdeskModel } from './helpdesk.model';
import { HelpdeskService } from './helpdesk.service';
import {Location} from '@angular/common';

@Component({
  selector: 'app-helpdesk',
  templateUrl: './helpdesk.component.html',
  styleUrls: ['./helpdesk.component.scss']
})
export class HelpdeskComponent implements OnInit {

  formTambahNpwpSpkd: FormGroup;
  formTiket: FormGroup;
  idHelpdesk: number;
  idTHeldpesk: number;
  namaTHelpdesk: String;
  opdModel: OpdModel;
  idOpd: any;

  listModule: any = [];
  listJenis: any = [];

  formSearch: FormGroup;
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject();

  @ViewChild('listSkpdModal', { static: false }) skpdModal: any;
  dataSkpd: OpdModel;

  submitForm = false;


  constructor(
    private formBuilder: FormBuilder,
    private _HelpdeskService: HelpdeskService,
    private _router: Router,
    private activatedRoute: ActivatedRoute,  
    private _toastr: ToastrService,
    private _location: Location
  ) {
    this.formTiket = this.formBuilder.group({
      codeModule: formBuilder.control(null),
      namaTopic : formBuilder.control(null),
      codeJenis: formBuilder.control(null),
      message : formBuilder.control(null),
      idOpd : formBuilder.control(this.idOpd),
      codeOpd : formBuilder.control(null),
    });
   }

  ngOnInit(): void {

    this.activatedRoute.params.subscribe(route => {
      this.idHelpdesk = route['idHelpdesk'] 
    });

    this.getListHelpdesk()

    this.getListOpd();

  }

  getListHelpdesk() {
    this._HelpdeskService.listCodeTiket("M").subscribe({
      next: (response) => {
        this.listModule = response;
        console.log(this.listModule);
      },
      error: (err) => {
        console.log(err);
      }
    });

    this._HelpdeskService.listCodeTiket("T").subscribe({
      next: (response) => {
        this.listJenis = response;
        console.log(this.listJenis);
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  getListOpd() {
    this.formSearch = this.formBuilder.group({
      codeOpd: this.formBuilder.control(null),
      namaOpd: this.formBuilder.control(null)
    });
    
    this.dtOptions = {
      lengthMenu: [[10, 25, 50, 100, -1], [10, 25, 50, 100, 'All']],
      pagingType: 'full_numbers',
      serverSide: true,
      searching: false,
      processing: true,
      autoWidth: false,
      dom: 't<\'row\'<\'col-sm-12\'ip><\'col-sm-12\'l>>',
      order: [1, 'desc'],
      ajax: (dataTablesParameters: any, callback) => {
        const search: OpdModel = this.formSearch.value;
        console.log('DataTables Search Value');
        console.log(search);
        this._HelpdeskService.opdDatatable(dataTablesParameters, search).subscribe(resp => {
          console.log('All List Example');
          console.log(resp.data);
          callback({
            recordsTotal: resp.recordsTotal,
            recordsFiltered: resp.recordsFiltered,
            data: resp.data
          });
        }, error => {
          callback({
            recordsTotal: 0,
            recordsFiltered: 0,
            data: []
          });
        });
      },
      columns: [
        {
          width: '20%',
          data: null,
          title: 'Pilih',
          orderable: false,
          className: 'text-center align-middle nopadding',
          render: (data: any, type: any, row: any, meta) => {
            return `<input type="radio" id="checkItem" name="checkItem"/>`;
          }
        },
        { width: '30%', data: 'kodeOpd', title: 'Kode', className: 'align-middle nopadding' },
        { width: '60%', data: 'namaOpd', title: 'Nama', className: 'align-middle nopadding' },
        
      ],
      rowCallback: (row: Node, data: OpdModel, index: number) => {
        $('input#checkItem', row).click(() => {
          console.log(data);
          this.dataSkpd = data;
        });
        return row;
      }
    };
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  refresh(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  pilihSkpd() {
    this.skpdModal.hide();
    this.formTiket.patchValue({ idOpd: this.dataSkpd.idOpd});
    this.formTiket.patchValue({ codeOpd: this.dataSkpd.kodeOpd});
  }

  goBack() {
    this._location.back();
}

  save(){
    console.log(this.formTiket.value);
    const paramSave: HelpdeskModel = this.formTiket.value;
    
    this._HelpdeskService.create(paramSave).subscribe((response) => {
      if (response.status == 200) {
        this._toastr.success('Data Berhasil Ditambahkan', 'Berhasil');
        this._router.navigate([this._location.back()]);
      } else {
        this._toastr.warning('Data Gagal Ditambahkan', 'Gagal');
      }
    }, error => {
      this._toastr.error('Tidak dapat menyimpan data', `Kesalahan Server - ${error.status}`);
    }
    );
  }
}

