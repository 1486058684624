import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { Observable } from 'rxjs';
import { routes } from 'src/app/app-routing.module';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

    public routers: typeof routes = routes;

    constructor(private _router: Router,
      private _authService: AuthenticationService) {
    }
  
    canActivate(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (this._authService.isAuthenticated() && !this._authService.isExpired()) {
        return true;
      } else {
        this._router.navigate(['/login']);
        return false;
      }
    }
}
