export class DatatablesSpdHomeDokumenRequest {
    tahunAnggaran?: string;
    idOpd?: number;
    noSpd?: string;
    status?: string;
    pegawaiTtd?: string;
}

export class DatatablesSpdHomeDokumenResponse {
    idSpd?: string;
    noSpd?: number;
    jenis?: string;
    tanggalSpd?: string;
    nilaiSpd?: string;
    statusSpd?: string;
    tanggalCetak?: string;
    pegawaiTtd?: string;
}

export class Sp2dDatatablesCetak {
    lokasiPdf?: string;
    namaPdf?: string;
    spdFilePdf?: any;
    jasperFile?: string;
    idSp2d?: number;
    idSpp?: number;
    idOpd?: number;
    kodeBeban?: string;
    kodeJenis?: string;
    kodeNihil?: string;
    kodePotongan?: string;
    kodeWilayahProses?: string;
    namaJenis?: string;
    namaKbud?: string;
    nilaiSp2d?: number;
    nipKbud?: string;
    noSpm?: string;
    noSpp?: string;
    noSp2d?: string;
    status?: string;
    tahunAnggaran?: string;
    tanggalSp2d?: string;
    tanggalSpm?: string;
    statusParaf1?: string;
    statusParaf2?: string;
    statusTte?: string;
    jenis?: string;
    alasanBatal?: string;
    noBa?: string;
}

export class BuktiPotonganPajakModel {
    kodeJenis?: string;
    idOpd?: any;
    idSpmPot?: any;
    idSp2d?: any;
    npwpBud?: string;
    namaBud?: string;
    alamatBud?: string;
    kotaBud?: string;
    noSp2d?: string;
    noSp2dFormat?: string;
    idRequest?: string;
    idBulkRequest?: string;
    tglSp2dSah?: string;
    kodeTrx?: string;
    idPotSpm?: any;
    nilaiPajak?: string;
    uraianPajak?: string;
    noKontrak?: string;
    npwpRekanan?: string;
    namaWp?: string;
    alamatWp?: string;
    kotaWp?: string;
    kodeBank?: string;
    noRekening?: string;
    namaPengirimBank?: string;
    kodeJenisSetor?: string;
    akunPajak?: string;
    bulanSah?: string;
    masaPajak?: string;
    noSk?: string;
    kodeBilling?: string;
    tglBillExp?: string;
    tglBuku?: string;
    statusBpn?: string;
    kodeskpd?: string;
    idSpmPotFormat?: string;
    namaOpd?: string;
    ntb?: string;
    ntpn?: string;
    tglBayar?: string;
}

export class Sp2dPencetakanDatatables {
    lokasiPdf?: string;
    namaPdf?: string;
    spdFilePdf?: any;
    jasperFile?: string;
    idSp2d?: number;
    idSpp?: any;
    idOpd?: number;
    kodeBeban?: string;
    kodeJenis?: string;
    kodeNihil?: string;
    kodePotongan?: string;
    kodeWilayahProses?: string;
    namaJenis?: string;
    namaKbud?: string;
    nilaiSp2d?: number;
    nipKbud?: string;
    noSpm?: string;
    noSpp?: string;
    noSp2d?: string;
    status?: string;
    namaWilayah? : string;
    tahunAnggaran?: string;
    tanggalSp2d?: string;
    tanggalSpm?: string;
    statusParaf1?: string;
    statusParaf2?: string;
    statusTte?: string;
    jenis?: string;
    alasanBatal?: string;
    noBa?: string;
    otoritasParaf?: string;
}


export class SpmPencetakanDatatables {
    idSpm?: number;
    idSpp?: number;
    idOpd?: number;
    noSpp?: any;
    noSpm?: any;
    namaPptk?: string;
    nipPptk?: string;
    namaBendahara?: string;
    nipBendahara?: string;
    kodeBeban?: string;
    kodeJenis?: string;
    tahunAnggaran?: string;
    filepdf?: string;
    filejasper?: string;
    status?: string;
    jenisSpp?: string;
    nilaiSpm?: number;
    peraturan?: string;
    jenis: string;
    kodeWilSp2dProses?: string;
}

export class SppCetakModel {
    idSpp?: number;
    idOpd?: number;
    noSpp?: string;
    tanggalSpp?: any;
    uraianSpp?: string;
    namaPptk?: string;
    nipPptk?: string;
    namaBendahara?: string;
    nipBendahara?: string;
    kodeBeban?: string;
    tahun?: string;
    filepdf?: string;
    filejasper?: string;
    status?: string;
    kodeJenis?: string;
    jenisSpp?: string;
    nilaiSpp?: any;
    nilaiRsk?: any;
    peraturan?: string;
    statusGlobal?: string;
    cariTanggalSppMulai?: any;
    cariTanggalSppAkhir?: any;
}