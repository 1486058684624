import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingHomeComponent } from './layouts/landing-home/landing-home.component';
import { AuthLoginComponent } from './layouts/auth-login/auth-login.component';
import { ProfileComponent } from './layouts/profile/profile.component';
import { DocumentDownloadComponent } from './layouts/document-download/document-download.component';
import { HelpdeskComponent } from './layouts/helpdesk/helpdesk.component';
import { AuthGuard } from './_services/auth/auth.guard';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
    },
    {
        path: 'home',
        loadChildren: () => import('./layouts/layouts.module').then(m => m.LayoutsModule),
    },
    {
        path: 'login',
        component: AuthLoginComponent
    },
    {
        path: 'profile',
        component: ProfileComponent
    },
    {
        path: 'document-download',
        component: DocumentDownloadComponent
    },
    {
        path: 'helpdesk',
        component: HelpdeskComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        // preloadingStrategy: PreloadAllModules,
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'enabled',
        initialNavigation: 'enabled'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
