import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from 'src/app/_services/auth/authentication.service';
import {CurrentUser} from 'src/app/_model/auth/authentication.model';
import { Location } from '@angular/common';

declare var require;
const Swal = require('sweetalert2');

@Component({
    selector: 'app-landing-home-header',
    templateUrl: './landing-home-header.component.html',
    styleUrls: ['./landing-home-header.component.scss']
})
export class LandingHomeHeaderComponent implements OnInit {

    userLogin = false;

    constructor(private router: Router, private authentication: AuthenticationService, private location: Location) {
    }

    ngOnInit() {
        const currentUser = this.authentication.currentUser;
        if (currentUser) {
            this.userLogin = !!currentUser.access_token;
        
            if (this.authentication.isExpired()) {
                this.router.navigate(['/login']).then(() => { window.location.reload(); });
            }
        }
    }
    
    goToLogin() {
        console.log('Login');
        this.router.navigate(['/', 'login']);
    }

    signOutAlert() {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false,
        });
        swalWithBootstrapButtons.fire({
            title: 'Anda Yakin',
            text: 'Ingin Keluar Aplikasi?',
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonText: 'Keluar',
            cancelButtonText: 'Batal',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                localStorage.clear();
                window.location.reload();
            }
        });
    }
}
