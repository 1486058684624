export const environment = {
  production: true,
  kodeAplikasi: 'SPD', // Kode Aplikasi Untuk Mengambil Data Sidebar Berdasarkan Kode Aplikasi
  clientId: 'sipkd-um-server',
  clientSecret: '123456',
  uriRbaApi: '/hasjrat-sales-tools',
  uriReportApi: '/sipkd-report-server',
  uriRbaAuth: '/blud-auth-server',
  uriSipkdAuth: '/sipkd-auth-server',
  uriSipkdSpm: '/sipkd-spm-server',
  uriSipkdUserManagement: '/sipkd-um-server',
  uriSipkdReferensi: '/sipkd-ref-server',
  uriSipkdSpd: '/sipkd-spd-server',
  uriSipkdSpp: '/sipkd-spp-server',
  uriSipkdSp2d: '/sipkd-sp2d-server',
};
