import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DatatablesModelResponse } from '../_model/utils/datatables.model';
import {DatatablesSpdHomeDokumenRequest, Sp2dDatatablesCetak, Sp2dPencetakanDatatables, SpmPencetakanDatatables} from '../_model/document-download.model';

@Injectable({ providedIn: 'root' })
export class DocumentDownloadService {

    constructor(private _http: HttpClient) {
    }

    public datatablesSpdHomeDokumen(parameters: any, value: DatatablesSpdHomeDokumenRequest) {
        let params = new HttpParams();
        params = params.append('start', parameters.start);
        params = params.append('length', parameters.length);
        params = params.append('draw', parameters.draw);
        params = params.append('order[0][column]', parameters.order[0]['column']);
        params = params.append('order[0][dir]', parameters.order[0]['dir']);
        return this._http.post<DatatablesModelResponse>(
            `${environment.uriSipkdSpd}/api/home-dokumen/datatables`, value, {params});
    }

    public filterJenis(params: any) {
        const search: any = params;
        if (search.jenis === 'up') {
            search.kodeJenis = '0';
            search.kodeBeban = 'UP';
        }

        if (search.jenis === 'gu') {
            search.kodeJenis = '0';
            search.kodeBeban = 'GU';
        }

        if (search.jenis === 'tu') {
            search.kodeJenis = '0';
            search.kodeBeban = 'TU';
        }

        if (search.jenis === 'gaji') {
            search.kodeJenis = '1';
            search.kodeBeban = 'LS';
        }

        if (search.jenis === 'nokontrak') {
            search.kodeJenis = '2';
            search.kodeBeban = 'LS';
        }

        if (search.jenis === 'kontrak') {
            search.kodeJenis = '3';
            search.kodeBeban = 'LS';
        }

        if (search.jenis === 'biaya') {
            search.kodeJenis = '4';
            search.kodeBeban = 'LS';
        }
        return search;
    }

    public datatablesSp2dCetak(parameters: any, value: Sp2dDatatablesCetak, idOpd: string) {
        const tahunAnggaran = localStorage.getItem('tahunAnggaran');
        let params = new HttpParams();
        params = params.append('start', parameters.start);
        params = params.append('length', parameters.length);
        params = params.append('draw', parameters.draw);
        params = params.append('order[0][column]', parameters.order[0]['column']);
        params = params.append('order[0][dir]', parameters.order[0]['dir']);
        params = params.append('idOpd', idOpd);
        params = params.append('tahunAnggaran', tahunAnggaran);
        return this._http.post<DatatablesModelResponse>(`${environment.uriSipkdSp2d}/api/doc/list/datatables`, value, {params: params});
    }

    public datatablesBuktiBayarPajak(parameters: any, value: Sp2dDatatablesCetak, idOpd: string) {
        const tahunAnggaran = localStorage.getItem('tahunAnggaran');
        let params = new HttpParams();
        params = params.append('start', parameters.start);
        params = params.append('length', parameters.length);
        params = params.append('draw', parameters.draw);
        params = params.append('order[0][column]', parameters.order[0]['column']);
        params = params.append('order[0][dir]', parameters.order[0]['dir']);
        params = params.append('idOpd', idOpd);
        params = params.append('tahunAnggaran', tahunAnggaran);
        return this._http.post<DatatablesModelResponse>(`${environment.uriSipkdSp2d}/api/bukti-potongan/list/datatables`, value, {params: params});
    }

    // public datatableTandaTerimaSpm(parameters: any, value: TandaTerimaDatatables, idOpd: string) {
    //     const tahunAnggaran = localStorage.getItem('tahunAnggaran');
    //     let params = new HttpParams();
    //     params = params.append('start', parameters.start);
    //     params = params.append('length', parameters.length);
    //     params = params.append('draw', parameters.draw);
    //     params = params.append('order[0][column]', parameters.order[0]['column']);
    //     params = params.append('order[0][dir]', parameters.order[0]['dir']);
    //     params = params.append('tahunAnggaran', tahunAnggaran);
    //     params = params.append('idOpd', idOpd);
    //     return this._http.post<DatatablesModelResponse>(`${environment.uriSipkdSp2d}/api/tanda-terima/list/datatables`, value, {params: params});
    // }

    public datatablePencetakan(parameters: any, value: Sp2dPencetakanDatatables, idOpd: string) {
        const tahunAnggaran = localStorage.getItem('tahunAnggaran');
        let params = new HttpParams();
        params = params.append('start', parameters.start);
        params = params.append('length', parameters.length);
        params = params.append('draw', parameters.draw);
        params = params.append('order[0][column]', parameters.order[0]['column']);
        params = params.append('order[0][dir]', parameters.order[0]['dir']);
        params = params.append('idOpd', idOpd);
        params = params.append('tahunAnggaran', tahunAnggaran);
        return this._http.post<DatatablesModelResponse>(`${environment.uriSipkdSp2d}/api/cetak/list/datatables`, value, {params: params});
    }

    public datatablesSpm(parameters: any, value: SpmPencetakanDatatables, idOpd: string) {
        let params = new HttpParams();
        const tahunAnggaran = localStorage.getItem('tahunAnggaran');
        params = params.append('start', parameters.start);
        params = params.append('length', parameters.length);
        params = params.append('draw', parameters.draw);
        params = params.append('order[0][column]', parameters.order[0]['column']);
        params = params.append('order[0][dir]', parameters.order[0]['dir']);
        params = params.append('tahunAnggaran', tahunAnggaran);
        params = params.append('idOpd', idOpd);
        return this._http.post<DatatablesModelResponse>(
            `${environment.uriSipkdSpm}/api/spm-cetak/list/datatables`, value, {params: params});
    }

    public datatablesSpp(parameters: any, value: any, idOpd: number, tahunAnggaran: string) {
        let params = new HttpParams();
        params = params.append('start', parameters.start);
        params = params.append('length', parameters.length);
        params = params.append('draw', parameters.draw);
        params = params.append('order[0][column]', parameters.order[0]['column']);
        params = params.append('order[0][dir]', parameters.order[0]['dir']);
        params = params.append('tahunAnggaran', tahunAnggaran);
        params = params.append('idOpd', idOpd.toString());
        return this._http.post<DatatablesModelResponse>(
            `${environment.uriSipkdSpp}/api/spp-cetak/datatables`, value, {params: params});
    }

    public documentDownloadSpdSp2d(value) {
        return this._http.get(`${environment.uriReportApi}/api/pdf/view/${value.module}/${value.nomor}/${value.idOpd}/${value.tahunAnggaran}/file`, 
        { observe: 'response', responseType: 'blob' });
    }

    public documentDownloadBuktiBayarPajak(idSpmPot, idRequest) {
        let params = new HttpParams();
        params = params.append('idSpmPot', idSpmPot);
        params = params.append('idRequest', idRequest);
        return this._http.post(`${environment.uriReportApi}/api/pdf/bukti-potongan/download-pdf`, null, {
            observe: 'response',
            params: params,
            responseType: 'blob'
        });
    }

    public documentDownloadBkuRealisasiRsk(tahunAnggaran, idOpd) {
        let params = new HttpParams();
        params = params.append('tahunAnggaran', tahunAnggaran);
        params = params.append('idOpd', idOpd);
        return this._http.post(`${environment.uriReportApi}/api/excel/bku-realisasi-rsk/download`, null, {
            observe: 'response',
            params: params,
            responseType: 'blob'
        });
    }

    public documentBpjsKesehatanNonAsnSatuPersen(tahunAnggaran, idOpd) {
        let params = new HttpParams();
        params = params.append('tahunAnggaran', tahunAnggaran);
        params = params.append('idOpd', idOpd);
        return this._http.post(`${environment.uriReportApi}/api/excel/bpjs-kesehatan-non-asn-satu-persen/download`, null, {
            observe: 'response',
            params: params,
            responseType: 'blob'
        });
    }

    public documentBpjsKesehatanNonAsnEmpatPersen(tahunAnggaran, idOpd) {
        let params = new HttpParams();
        params = params.append('tahunAnggaran', tahunAnggaran);
        params = params.append('idOpd', idOpd);
        return this._http.post(`${environment.uriReportApi}/api/excel/bpjs-kesehatan-non-asn-empat-persen/download`, null, {
            observe: 'response',
            params: params,
            responseType: 'blob'
        });
    }

    public documentDownloadPdfTandaTerima(idSpm: string, tahunAnggaran: string) {
        let params = new HttpParams();
        params = params.append('idSpm', idSpm);
        params = params.append('tahunAnggaran', tahunAnggaran);
        return this._http.post(`${environment.uriReportApi}/api/pdf/tanda-terima-sp2d/download-pdf`, null, {
            observe: 'response',
            params: params,
            responseType: 'blob'
        });
    }

    public documentDownloadPdfLaporanPembayaranBpjsKesehatan(idOpd, tahunAnggaran,tanggalAwal,tanggalAkhir) {
        let params = new HttpParams();
        params = params.append('idOpd', idOpd);
        params = params.append('tahunAnggaran', tahunAnggaran);
        params = params.append('tanggalAwal', tanggalAwal);
        params = params.append('tanggalAkhir', tanggalAkhir);
        return this._http.post(`${environment.uriReportApi}/api/pdf/laporan-pembayaran-bpjs/download-pdf`, null, {
            observe: 'response',
            params: params,
            responseType: 'blob'
        });
    }

}
