<!-- Navbar section Start-->
<header class="saas1 header-fixed loding-header position-absolute  custom-scroll nav-lg">
    <div class="container">
        <div class="row">
            <div class="col">
                <nav>
                    <a href="#" class="d-inline-block m-r-auto text-center">
                        <img src="assets/images/logo/dki_logo.png" width="100px" alt="" class="img-fluid">
                        <strong style="color: aliceblue;">
                            <br>
                            SIPD DKI JAKARTA</strong>
                    </a>
                    <app-menu></app-menu>
                </nav>
            </div>
        </div>
    </div>
</header>
<!-- Navbar section end-->
