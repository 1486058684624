import {Location} from '@angular/common';
import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {CurrentUser, PenggunaLogin} from 'src/app/_model/auth/authentication.model';
import {GlobalService} from 'src/app/_services/global.service';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

    dataUser: PenggunaLogin;
    formDataUser: FormGroup;
    formGantiPass: FormGroup;
    submittedGantiPass = false;
    passBaruShow = false;
    konfirmasPassBaruShow = false;

    @ViewChild('modalSuntingPassword')
    modalSuntingPassword;

    constructor(
        private location: Location,
        private globalService: GlobalService,
        private formBuilder: FormBuilder,
        private toastr: ToastrService
    ) {
    }

    ngOnInit(): void {
        this.formDataUser = this.formBuilder.group({
            penggunaLogin: this.formBuilder.control({value: null, disabled: true}),
            namaPengguna: this.formBuilder.control({value: null, disabled: true}),
            nipPengguna: this.formBuilder.control({value: null, disabled: true}),
            email: this.formBuilder.control({value: null, disabled: true}),
            penggunaId: this.formBuilder.control({value: null, disabled: true})
        });

        this.formGantiPass = this.formBuilder.group({
            idPengguna: this.formBuilder.control(null),
            passBaru: this.formBuilder.control(null, [Validators.required]),
            konfirmasiPassBaru: this.formBuilder.control(null, [Validators.required])
        });

        const currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser'));
        const idPengguna = currentUser.pengguna.penggunaId;
        console.log(idPengguna);
        this.dataUser = currentUser.pengguna;
        console.log(this.dataUser);
        this.formDataUser.patchValue(currentUser.pengguna);
    }

    goBack() {
        this.location.back();
    }

    gantiPassword() {
        this.formGantiPass.patchValue({
            idPengguna: this.formDataUser.get('penggunaId').value
        });
        this.submittedGantiPass = true;
        const passBaru: string = this.formGantiPass.get('passBaru').value;
        const konfirmasiPassBaru: string = this.formGantiPass.get('konfirmasiPassBaru').value;
        if (this.formGantiPass.invalid) {
            this.submittedGantiPass = false;
            return;
        }
        if (passBaru !== konfirmasiPassBaru) {
            this.passBaruShow = true;
            this.konfirmasPassBaruShow = true;
            this.toastr.warning('Password Tidak Sama');
            this.submittedGantiPass = false;
            return;
        }
        console.log('Form Update Password');
        console.log(this.formGantiPass.value);
        // this._spinner.show('simpanData');
        this.globalService.saveGantiPass(this.formGantiPass.value).subscribe((response) => {
            if (response.status === 200) {
                this.toastr.success('Berhasil Ganti Password');
                // this.findPenggunaById(this.penggunaId);
            } else if (response.status === 204) {
                this.toastr.warning(response.body.toString(), 'Gagal Ganti Password');
            }
            this.submittedGantiPass = false;
            this.formGantiPass.reset();
            // this._spinner.hide('simpanData');
            this.modalSuntingPassword.hide();
        }, error => {
            // this._spinner.hide('simpanData');
            this.modalSuntingPassword.hide();
            this.toastr.error('Gagal Ganti Password', 'Kesalahan Server');
        });
    }
}
