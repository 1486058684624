import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing-home-feature',
  templateUrl: './landing-home-feature.component.html',
  styleUrls: ['./landing-home-feature.component.scss']
})
export class LandingHomeFeatureComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  features = [
    {
      title: 'SPD',
      subTitle: '',
      // subTitle: '(Surat Penyediaan Dana)',
      description: 'Surat Penyediaan Dana yang selanjutnya disingkat SPD adalah dokumen yang menyatakan tersedianya dana untuk melaksanakan kegiatan sebagai dasar penerbitan SPP.',
      img: 'assets/images/saas1/feature/5.png',
    },
    {
      title: 'SPP',
      subTitle: '',
      // subTitle: '(Surat Permintaan Pembayaran)',
      description: 'Surat Permintaan Pembayaran yang selanjutnya disingkat SPP adalah dokumen yang diterbitkan oleh pejabat yang bertanggung jawab atas pelaksanaan kegiatan/bendahara pengeluaran untuk mengajukan permintaan pembayaran.',
      img: 'assets/images/saas1/feature/2.png',
    },
    {
      title: 'SPM',
      subTitle: '',
      // subTitle: '(Surat Perintah Membayar)',
      description: 'Surat Perintah Membayar yang selanjutnya disingkat SPM adalah dokumen yang digunakan/diterbitkan oleh pengguna anggaran/kuasa pengguna anggaran untuk penerbitan SP2D atas beban pengeluaran DPA-SKPD.',
      img: 'assets/images/saas1/feature/3.png',
    },
    {
      title: 'SP2D',
      subTitle: '',
      // subTitle: '(Surat Perintah Pencairan Dana)',
      description: 'Surat Perintah Pencairan Dana yang selanjutnya disingkat SP2D adalah dokumen yang digunakan sebagai dasar pencairan dana yang diterbitkan oleh BUD berdasarkan SPM.',
      img: 'assets/images/saas1/feature/4.png',
    },
    {
      title: 'SPJ',
      subTitle: '',
      // subTitle: '(Surat Pertanggungjawaban)',
      description: 'Surat Pertanggungjawaban yang selanjutnya disingkat SPJ.',
      img: 'assets/images/saas1/feature/5.png',
    },
    // {
    //   title: 'Well',
    //   subTitle: 'Documented',
    //   description: 'Responsive code that makes your landing page look good on all devices (desktops,tablets, and phones). Created with mobile specialists',
    //   img: 'assets/images/saas1/feature/6.png',
    // }
  ]
}
