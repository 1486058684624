import {Component, OnInit} from '@angular/core';
import {GlobalService} from 'src/app/_services/global.service';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {AuthenticationService} from 'src/app/_services/auth/authentication.service';
import {Authentication, CurrentUser, Token} from 'src/app/_model/auth/authentication.model';

@Component({
    selector: 'app-auth-login',
    templateUrl: './auth-login.component.html',
    styleUrls: ['./auth-login.component.scss']
})
export class AuthLoginComponent implements OnInit {
    public seconds: number;
    public timer: any;

    public user: Authentication;
    public loginForm: FormGroup;
    showLoader = false;
    submitted = false;
    captchaValue = '';
    listTahunAnngaran: string[] = [];

    constructor(
        private _globalService: GlobalService,
        private _toastr: ToastrService,
        private _authService: AuthenticationService,
        private fb: FormBuilder,
        private router: Router) {
    }

    ngOnInit() {
        localStorage.clear();
        const currentUser: CurrentUser = this._authService.currentUser;
        if (currentUser?.access_token) {
            this._toastr.success('Telah Login');
            this.router.navigate(['/', 'home']);
        }
        this.loginForm = this.fb.group({
            username: this.fb.control(null, [Validators.required]),
            password: this.fb.control(null, [Validators.required]),
            tahunAnggaran: this.fb.control(null, [Validators.required]),
            captcha: this.fb.control(null, [Validators.required])
        });
        this.randomCaptcha(5);
        this.findTahunAnggaran();
    }

    randomCaptcha(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        this.captchaValue = result;
        return result;
    }

    findTahunAnggaran() {
        const tahunAnggaran = this.loginForm.get('username').value;
        this._globalService.getTahunAnggaran(tahunAnggaran ? tahunAnggaran : '123').subscribe((response: any) => {
            console.log('Response Tahun Anggaran');
            console.log(response);
            if (response.status === 200) {
                this.listTahunAnngaran = response.body;
                this.loginForm.patchValue({tahunAnggaran: this.listTahunAnngaran[3]});
            }
        }, error => {

        });
    }

    checkCaptcha(): boolean {
        return !((this.loginForm.get('captcha').value === this.captchaValue) && this.loginForm.get('captcha').value.length >= 5);
    }

    checkForm() {
        return this.loginForm.controls;
    }

    login() {
        this.submitted = true;
        this.user = this.loginForm.value;
        this.showLoader = true;
        console.log(this.loginForm.value);
        console.log(this.user);
        if (!this.loginForm.valid) {
            this._toastr.error('Silahkan lengkapi isian login');
            this.showLoader = false;
            return;
        }
        if (this.loginForm.get('captcha').value !== this.captchaValue) {
            this._toastr.error('Kode Captcha Tidak Sesuai');
            this.showLoader = false;
            return;
        }
        this._authService.login(this.user).subscribe(response => {
            console.log('Response Login: ');
            console.log(response);
            this.showLoader = false;
            if (response.status === 200) {
                const token: Token = response.body;
                this._authService.setToken(token);
                localStorage.setItem('tahunAnggaran', this.loginForm.get('tahunAnggaran').value);
                this.router.navigate(['/home']);
            }
            if (response.status === 400) {
                console.log('username & password salah!');
                this._toastr.warning('Username atau password salah!');
            }
        }, error => {
            this.showLoader = false;
            if (error.status === 400) {
                this._toastr.warning('Username atau password salah!');
            } else {
                this._toastr.error('Gagal login, silahkan hubungi admin', 'Server Error');
            }
            console.log(error);
        });

    }

}
