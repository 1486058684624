import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DocumentDownloadComponent } from './document-download/document-download.component';
import { LandingHomeComponent } from './landing-home/landing-home.component';
import { ProfileComponent } from './profile/profile.component';
import { AuthGuard } from '../_services/auth/auth.guard';

const routes: Routes = [
    {
        path: '',
        children: [
            {
                path: '',
                component: LandingHomeComponent,
                data: {
                    title: 'SIPKD (Sistem Informasi Pengelolaan Keuangan Daerah) | DKI JAKARTA'
                }
            },
            {
                path: 'profile',
                canActivate: [AuthGuard],
                component: ProfileComponent,
                data: {
                    title: 'Profile'
                }
            },
            {
                path: 'manual',
                loadChildren: () => import('./manual/manual.module').then(m => m.ManualModule),
                data: {
                    breadcrumb: 'Manual'
                }
            },
            {
                path: 'document-download',
                canActivate: [AuthGuard],
                component: DocumentDownloadComponent,
                data: {
                    title: 'Document Download'
                }
            }
        ],

    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class LayoutsRoutingModule {
}
