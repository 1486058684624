<!--footer start-->
<footer class="saas1 footer2">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="logo-sec">
                    <div class="footer-title mobile-title">
                        <h3 class="text-white">Contact Us</h3>
                    </div>
                    <div class="footer-contant">
                        <img src="assets/images/logo/dki_logo.png" width="100px" class="img-fluid footer-logo">
                        <div class="footer-para">
                            <h6 class="text-white para-address">BPKD (Badan Pengelola Keuangan Daerah)</h6>
                            <h6 class="text-white para-address">Gedung Balai Kota </h6>
                            <h6 class="text-white para-address">Jl. Medan Merdeka Selatan 8-9 Blok G Lt 14.</h6>
                            <h6 class="text-white para-address">Phone : 62.21.382.xxx.</h6>
                            <h6 class="text-white para-address">Fax : 62.21.382.xxx.</h6>
                            <h6 class="text-white para-address">E-mail : sipkd@jakarta.go.id.</h6>
                        </div>
                        <ul class="d-d-flex footer-social social">
                            <li class="footer-social-list">
                                <a href="javascript:void(0)"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                            </li>
                            <li class="footer-social-list">
                                <a href="javascript:void(0)"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                            </li>
                            <li class="footer-social-list">
                                <a href="javascript:void(0)"><i class="fa fa-google" aria-hidden="true"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- <div class="col-md-3">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">services</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">services</h5>
                    <div>
                        <ul class="footer-lists">
                            <li class=""><a href="#">Portfolio Website</a></li>
                            <li class=""><a href="#">Set up an account</a></li>
                            <li class=""><a href="#">Invoice Creation</a></li>
                            <li class=""><a href="#">Get Our Specials</a></li>
                        </ul>
                    </div>
                </div>
            </div> -->
            <div class="col-md-6">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">aplikasi</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">aplikasi</h5>
                    <div>
                        <ul class="footer-lists">
                            <li class=""><a href="javascript:void(0)">SPD</a></li>
                            <li class=""><a href="javascript:void(0)">SPP</a></li>
                            <li class=""><a href="javascript:void(0)">SPM</a></li>
                            <li class=""><a href="javascript:void(0)">SP2D</a></li>
                            <li class=""><a href="javascript:void(0)">SPJ</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- <div class="col-md-3">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">Support</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">Support</h5>
                    <div>
                        <ul class="footer-lists">
                            <li class=""><a href="#">For Freelancers</a></li>
                            <li class=""><a href="#">For Companies</a></li>
                            <li class=""><a href="#">For Business</a></li>
                            <li class=""><a href="#">General Help</a></li>
                        </ul>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</footer>
<!--footer end-->
<app-tap-to-top></app-tap-to-top>
