import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing-home-copyright',
  templateUrl: './landing-home-copyright.component.html',
  styleUrls: ['./landing-home-copyright.component.scss']
})
export class LandingHomeCopyrightComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
