<section class="coming-soon p-0">
    <div class="container-fluid p-0">
        <div class="row text-center">
            <div class="col-md-6 p-0 set-bg-img">
                <div class="bg-coming-soon" [ngStyle]="{'background-image': 'url(assets/images/IMG_9079_1366_edit.jpg)'}">
                    <div class="center-container" style="padding: 50px 50px;">
                        <!-- <h2 class="text-white our-web-text p-t-10">SIPKD DKI JAKARTA</h2>
                        <h6 class="launch-text">Sistem Informasi Pengelolaan Keuangan Daerah - DKI Jakarta</h6> -->
                    </div>
                </div>
            </div>
            <div class="col-md-6 bg-black">
                <div class="center-container">
                    <div>
                        <div>
                            <img [routerLink]="['/', 'home']" src="assets/images/logo/dki_logo.png" width="100px" alt=""
                                class="dki-logo">
                        </div>
                        <h2 class="text-white our-web-text p-t-10">SIPD - SIKD DKI JAKARTA</h2>
                        <h6 class="launch-text">Selamat Datang, Silahkan Login</h6>
                    </div>
                    <div class="">
                        <form class="center-content" [formGroup]="loginForm">
                            <div class="form-group">
                                <div class="d-flex">
                                    <select formControlName="tahunAnggaran" class="form-control radius-0"
                                        [ngClass]="{ 'is-invalid': submitted && loginForm.controls.tahunAnggaran.errors}">
                                        <option [value]="null" disabled>Tahun Anggaran</option>
                                        <option *ngFor="let tahun of listTahunAnngaran" [value]="tahun">{{tahun}}
                                        </option>
                                    </select>
                                    <div *ngIf="submitted && loginForm.controls.tahunAnggaran.errors"
                                        class="invalid-feedback pd-valid-form">
                                        <div *ngIf="loginForm.controls.tahunAnggaran.errors?.required"
                                            class="text text-danger pd-valid-form">
                                            Wajib diisi.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="d-flex">
                                    <input type="text" class="form-control radius-0" placeholder="ID Login"
                                        name="username" formControlName="username"
                                        [ngClass]="{ 'is-invalid': submitted && loginForm.controls.username.errors}">
                                    <div *ngIf="submitted && loginForm.controls.username.errors"
                                        class="invalid-feedback pd-valid-form">
                                        <div *ngIf="loginForm.controls.username.errors?.required"
                                            class="text text-danger pd-valid-form">
                                            Wajib diisi.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="d-flex">
                                    <input type="password" class="form-control radius-0" placeholder="Password"
                                        name="password" formControlName="password"
                                        [ngClass]="{ 'is-invalid': submitted && loginForm.controls.password.errors}">
                                    <div *ngIf="submitted && loginForm.controls.password.errors"
                                        class="invalid-feedback pd-valid-form">
                                        <div *ngIf="loginForm.controls.password.errors?.required"
                                            class="text text-danger pd-valid-form">
                                            Wajib diisi.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="d-flex">
                                    <input type="text" class="form-control radius-0" placeholder="Ketik Captcha"
                                        name="captcha" formControlName="captcha" [ngClass]="{
                                            'is-invalid': submitted && loginForm.controls.captcha.errors || checkCaptcha(), 
                                            'is-valid': !loginForm.controls.captcha.valid || !checkCaptcha()}">
                                    &nbsp;
                                    <input
                                        style="font-style: italic; font-size: xx-large; background-color: darkslategray; color: white; padding: 0;"
                                        readonly type="text" class="form-control text-center radius-0" name="captcha"
                                        [value]="captchaValue" (click)="randomCaptcha(5)">
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="col-md-12">
                                    <button typeof="submit" style="border-radius: 35px; margin-left: 0; width: 100%;"
                                        class="btn btn-default primary-btn light" [disabled]="showLoader"
                                        (click)="login()" type="submit"><i class="fa fa-refresh"></i>{{ showLoader ? ''
                                        : 'Login' }}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="center-content" style="color: white; font-size: 11px; margin-top: 50px;">
                        <div class="row row-custom">
                            <span>BPKD (Badan Pengelola Keuangan Daerah)</span>
                        </div>
                        <div class="row row-custom">
                            <span>Gedung Balai Kota Jl. Medan Merdeka Selatan 8-9 Blok G Lt 14</span>
                        </div>
                        <div class="row row-custom">
                            <span>Jakarta Pusat - Daerah Khusus Ibukota Jakarta</span>
                        </div>
                        <div class="row row-custom">
                            <span>Sertifikat elektronik dijamin oleh</span>
                        </div>
                        <div class="row row-custom">
                            <img src="assets/images/logo/BSE_BSDSN_LOGO-01.png" width="192px" height="57px">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>