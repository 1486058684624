<!--copyright start-->
<div class="saas1 copyright">
    <div class="container">
        <div class="row">
            <div class="col-sm-6">
                <div class="link-horizontal center-text">
                    <ul>
                        <li>
                            <a href="#" class="copyright-text op-text" style="font-size: 11px">PUSAT DATA DAN INFORMASI
                                KEUANGAN © 2020</a>
                        </li>
                        <!-- <li>
                            <a href="#" class="copyright-text op-text">Terms &amp; Conditions</a>
                        </li> -->
                    </ul>
                </div>
            </div>
            <div class="col-sm-6">
                <div>
                    <p class="copyright-text text-white text-right op-text" style="font-size: 11px">Powered by Sistem
                        Informasi Pemerintahan
                        Daerah (SIPD) Pemprov DKI</p>
                </div>
            </div>
        </div>
    </div>
</div>
<!--copyright end-->
