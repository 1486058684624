import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Authentication, CurrentUser, Token } from '../../_model/auth/authentication.model';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

    private currentLogin = 'currentUser';
    private userProfile = 'userProfile';
    private tahunAnggaran = 'tahunAnngaran';
    jwtHelperService: JwtHelperService = new JwtHelperService();

    constructor(private http: HttpClient) {
    }

    isAuthenticated(): boolean {
        return localStorage.getItem(this.currentLogin) != null;
    }

    isExpired(): boolean {
        if (!this.currentUser.exp) {
            return true;
        }
        let expired = this.currentUser.exp;
        let currentMillis: any = Date.now();
        return expired.valueOf() * 1000 <= currentMillis;
    }
    
    

    get currentUser(): CurrentUser {
        const currentLogin: CurrentUser = JSON.parse(this.isAuthenticated() ? localStorage.getItem(this.currentLogin) : null);
        return currentLogin;
    }

    login(auth: Authentication) {
        console.log('Login Service');
        let header = new HttpHeaders();
        const authBase64 = btoa(environment.clientId + ':' + environment.clientSecret);
        header = header.append('Authorization', `Basic ${authBase64}`);

        let params = new HttpParams();
        params = params.append('username', auth.username);
        params = params.append('password', auth.password);
        params = params.append('grant_type', 'password');

        return this.http.post<Token>(`${environment.uriSipkdAuth}/oauth/token`,
            {},
            {
                headers: header,
                params: params,
                observe: 'response'
            });
    }

    setToken(token: Token): void {
        const login: CurrentUser = this.jwtHelperService.decodeToken(token.access_token);
        login.access_token = token.access_token;
        login.expired = this.jwtHelperService.isTokenExpired(token.access_token);
        localStorage.setItem(this.currentLogin, JSON.stringify(login));
    }
}
