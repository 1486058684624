import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DatatablesModelResponse } from '../_model/utils/datatables.model';
import { OpdModel } from '../_model/opd.model';
import { CsvModel } from '../_model/csv.model';

@Injectable({ providedIn: 'root' })
export class GlobalService {

    constructor(private _http: HttpClient) {
    }

    public getTahunAnggaran(param: string) {
        return this._http.get(`${environment.uriSipkdAuth}/api/public/tahun-anggaran/${param}/by-nrk`, { observe: 'response' });
    }


    public convertMenuToTreeModel(menuData: any[]): any[] {
        return menuData.map(value => {
            return {
                label: value.namaMenu,
                data: value.idMenu,
                children: value.children
            };
        });
    }

    public getListPengumuman(idAplikasi) {
        return this._http.get(`${environment.uriSipkdReferensi}/api/pengumuman/list-berlaku-berakhir/${idAplikasi}`, { observe: 'response' });
    }

    public getProfile(id: number) {
        return this._http.get(`${environment.uriSipkdUserManagement}/api/pengguna/${id}/findById`);
    }

    public saveGantiPass(value) {
        return this._http.post(`${environment.uriSipkdUserManagement}/api/pengguna/ganti-password`, value, { observe: 'response' });
    }

    public datatableSkpdOpd(parameters: any, value: OpdModel) {
        const tahunAnggaran = localStorage.getItem('tahunAnggaran');
        let params = new HttpParams();
        params = params.append('start', parameters.start);
        params = params.append('length', parameters.length);
        params = params.append('draw', parameters.draw);
        params = params.append('order[0][column]', parameters.order[0]['column']);
        params = params.append('order[0][dir]', parameters.order[0]['dir']);
        params = params.append('tahunAnggaran', tahunAnggaran);
        return this._http.post<DatatablesModelResponse>(`${environment.uriSipkdReferensi}/api/skpd-opd/datatables`, value, { params: params });
    }

    public datatableOpdByPengguna(parameters: any, value: OpdModel) {
        let params = new HttpParams();
        params = params.append('start', parameters.start);
        params = params.append('length', parameters.length);
        params = params.append('draw', parameters.draw);
        params = params.append('order[0][column]', parameters.order[0]['column']);
        params = params.append('order[0][dir]', parameters.order[0]['dir']);
        return this._http.post<DatatablesModelResponse>(`${environment.uriSipkdAuth}/api/opd/datatables/by-pengguna`, value, { params: params });
    }

    // Keperluan CSV
    public listCsv() {
        return this._http.get(`${environment.uriSipkdReferensi}/api/csv/list`, { observe: 'response' });
    }

    public generateCsv(csvFile: CsvModel) {
        return this._http.post(`${environment.uriSipkdReferensi}/api/csv/generate`, csvFile, {
            observe: 'response',
            responseType: 'blob'
        });
    }
}
