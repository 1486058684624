import {HttpResponse} from '@angular/common/http';
import {AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild, ViewEncapsulation} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {PengumumanModel} from 'src/app/_model/pengumuman.model';
import {AuthenticationService} from 'src/app/_services/auth/authentication.service';
import {GlobalService} from 'src/app/_services/global.service';

@Component({
    selector: 'app-landing-home',
    templateUrl: './landing-home.component.html',
    styleUrls: ['./landing-home.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LandingHomeComponent implements OnInit, AfterViewInit {

    @ViewChild('lgModal')
    private lgModal;

    listPengumuman: PengumumanModel[] = [];

    @ViewChild('buttonLeft')
    buttonLeft: ElementRef;

    constructor(
        private route: ActivatedRoute,
        private title: Title,
        private globalService: GlobalService,
        private renderer: Renderer2,
        private authService: AuthenticationService) {
    }


    ngOnInit() {
        this.title.setTitle(this.route.snapshot.data['title']);
        this.getListPengumuman();
    }

    getListPengumuman() {
        this.globalService.getListPengumuman("1").subscribe((resp: HttpResponse<PengumumanModel[]>) => {
            console.log('Response Pengumuman List');
            if (resp.status === 200) {
                // console.log(resp.body);
                this.listPengumuman = resp.body;
                if (this.listPengumuman.length > 0) this.lgModal.show();
            }
        });
    }

    ngAfterViewInit() {
    }
}
