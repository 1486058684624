import {DOCUMENT} from '@angular/common';
import {Component, OnInit, Inject} from '@angular/core';
import {Router} from '@angular/router';

@Component({
    selector: 'app-landing-home-nav',
    templateUrl: './landing-home-nav.component.html',
    styleUrls: ['./landing-home-nav.component.scss']
})
export class LandingHomeNavComponent implements OnInit {

    constructor(
        private router: Router,
        @Inject(DOCUMENT) private docGo: Document,
    ) {
    }

    ngOnInit() {
    }

}
