export class CsvModel {

    id?: number;
    namaTable?: string;
    keteranganTable?: string;
    query?: string;
    query2?: string;
    query3?: string;
    namaFileCsv?: string;
    kodeParamTahun?: number;
    kodeParamBulan?: number;
    kodeParamTglAwal?: number;
    kodeParamTglAkhir?: number;
    kodeParamOpd?: number;
    kodeParamWilSp2d?: number;
    kodeParamWilSpd?: number;
    kodeParamX1?: number;
    kodeParamX2?: number;
    kodeParamX3?: number;
    kodeParamX4?: number;
    kodeParamX5?: number;
    kodeAktif?: string;
    idPenggunaRekam?: string;
    tglPenggunaRekam?: any;
    idPenggunaUbah?: string;
    tglPenggunaUbah?: any;

}