<header class="ecommerce">
    <div class="container">
        <div class="row">
            <div class="col">
                <nav>
                    <a href="#" class="d-inline-block m-r-auto">
                        <img src="assets/images/logo/dki_logo.png" width="100px" alt="" class="img-fluid">
                        <strong style="color: black;">SIPD DKI JAKARTA</strong>
                    </a>
                    <app-menu></app-menu>
                </nav>
            </div>
        </div>
    </div>
</header>

<section id="feature" class="saas1 service">
    <div class="about-chat">
        <div class="container">
            <div class="row" style="padding: 20px;">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4>Dokumen Download</h4>
                        </div>
                        <div class="card-body">
                            <form class="form theme-form">
                                <!-- kolom 1 -->
                                <div class="row p-t-10">
                                    <div class="col col-xl-6 col-lg-6 col-md-6 col-xs-12 col-sm-12">

                                        <div class="row form-group" [formGroup]="formParamCsv">
                                            <label class="col-sm-4 col-form-label">Tahun</label>
                                            <div class="col-sm-8">
                                                <div class="input-group input-group-sm">
                                                    <input type="text" name="kodeParamTahun" id="kodeParamTahun"
                                                        class="form-control" formControlName="paramTahun" readonly>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="form-group row" [formGroup]="formDownload">
                                            <label class="col-sm-4 col-form-label">Modul</label>
                                            <div class="col-sm-8">
                                                <div class="input-group input-group-sm">
                                                    <select class="form-control" formControlName="module"
                                                        (change)="moduleChange()">
                                                        <option *ngFor="let value of listModule" [value]="value">
                                                            {{value}}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row form-group" [formGroup]="formParamCsv"
                                            *ngIf="formDownload.get('module').value == 'Generate CSV'">
                                            <label class="col-sm-4 col-form-label">Pilih Jenis CSV</label>
                                            <div class="col-sm-8">
                                                <div class="input-group input-group-sm">
                                                    <select class="form-control" (change)="pilihCsv($event)">
                                                        <option [value]=null></option>
                                                        <option *ngFor="let csv of listCsv" [value]="csv.namaTable">
                                                            {{csv.namaTable}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group row" [formGroup]="formDownload">
                                            <label class="col-sm-4 col-form-label">Kode / Nama SPKD</label>
                                            <div class="col-sm-8">
                                                <div class="input-group">
                                                    <textarea readonly rows="2" id="idOpd" name="idOpd"
                                                        formControlName="namaOpd" class="form-control"></textarea>
                                                    <div class="input-group-append">
                                                        <button (click)="listSkpdOpdModal.toggle()" id="btn_modal_opd"
                                                            name="btn_modal_opd" type="button"
                                                            class="btn btn-primary fa fa-search"></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row form-group" [formGroup]="formParamCsv"
                                            *ngIf="formDownload.get('module').value == 'Generate CSV' && selectedCsv.kodeParamBulan==1">
                                            <label class="col-sm-4 col-form-label">Bulan</label>
                                            <div class="col-sm-8">
                                                <div class="input-group input-group-sm">
                                                    <input type="text" name="kodeParamBulan" id="kodeParamBulan"
                                                        formControlName="paramBulan" class="form-control">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row form-group" [formGroup]="formParamCsv"
                                            *ngIf="formDownload.get('module').value == 'Generate CSV' && selectedCsv.kodeParamTglAwal==1">
                                            <label class="col-sm-4 col-form-label">Tgl Awal</label>
                                            <div class="col-sm-8">
                                                <div class="input-group input-group-sm">
                                                    <input type="text" name="kodeParamTglAwal" id="kodeParamTglAwal"
                                                        formControlName="paramTglAwal" class="form-control">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row form-group" [formGroup]="formParamCsv"
                                            *ngIf="formDownload.get('module').value == 'Generate CSV' && selectedCsv.kodeParamTglAkhir==1">
                                            <label class="col-sm-4 col-form-label">Tgl Awal</label>
                                            <div class="col-sm-8">
                                                <div class="input-group input-group-sm">
                                                    <input type="text" name="kodeParamTglAkhir" id="kodeParamTglAkhir"
                                                        formControlName="paramTglAkhir" class="form-control">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group row"
                                            *ngIf="this.formDownload.value.module == 'SPD' && formDownload.get('module').value != 'Generate CSV'"
                                            [formGroup]="formDownload">
                                            <label class="col-sm-4 col-form-label">Pilih SPD</label>
                                            <div class="col-sm-8">
                                                <div class="input-group">
                                                    <textarea readonly rows="2" id="pilihSpd" name="pilihSpd"
                                                        formControlName="nomor" class="form-control"></textarea>
                                                    <div class="input-group-append">
                                                        <button (click)="listSpdModal.toggle()" id="btn_modal_spd"
                                                            name="btn_modal_spd" type="button"
                                                            class="btn btn-primary fa fa-search"></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group row"
                                            *ngIf="this.formDownload.value.module == 'SP2D' && formDownload.get('module').value != 'Generate CSV'"
                                            [formGroup]="formDownload">
                                            <label class="col-sm-4 col-form-label">Pilih SP2D</label>
                                            <div class="col-sm-8">
                                                <div class="input-group">
                                                    <textarea readonly rows="2" id="pilihSp2d" name="pilihSp2d"
                                                        formControlName="nomor" class="form-control"></textarea>
                                                    <div class="input-group-append">
                                                        <button (click)="listSp2dModal.toggle()" id="btn_modal_sp2d"
                                                            name="btn_modal_sp2d" type="button"
                                                            class="btn btn-primary fa fa-search"></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group row"
                                            *ngIf="this.formDownload.value.module == 'Bukti Bayar Pajak' && formDownload.get('module').value != 'Generate CSV'"
                                            [formGroup]="formDownload">
                                            <label class="col-sm-4 col-form-label">Pilih Bukti Bayar Pajak</label>
                                            <div class="col-sm-8">
                                                <div class="input-group">
                                                    <textarea readonly rows="2" id="pilihButiBayarPajak"
                                                        name="pilihButiBayarPajak" formControlName="nomor"
                                                        class="form-control"></textarea>
                                                    <div class="input-group-append">
                                                        <button (click)="listBuktiBayarPajakModal.toggle()"
                                                            id="btn_modal_bukti_bayar_pajak"
                                                            name="btn_modal_bukti_bayar_pajak" type="button"
                                                            class="btn btn-primary fa fa-search"></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group row"
                                        *ngIf="this.formDownload.value.module == 'Tanda Terima SPM' && formDownload.get('module').value != 'Generate CSV'"
                                        [formGroup]="formDownload">
                                        <label class="col-sm-4 col-form-label">Pilih Tanda Terima SPM</label>
                                        <div class="col-sm-8">
                                            <div class="input-group">
                                                <textarea readonly rows="2" id="pilihTandaTerimaSpm"
                                                    name="pilihTandaTerimaSpm" formControlName="nomor"
                                                    class="form-control"></textarea>
                                                <div class="input-group-append">
                                                    <button (click)="listTandaTerimaSpmModal.toggle()"
                                                        id="btn_modal_tanda_terima_spm"
                                                        name="btn_modal_tanda_terima_spm" type="button"
                                                        class="btn btn-primary fa fa-search"></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group row"
                                        *ngIf="this.formDownload.value.module == 'SPM' && formDownload.get('module').value != 'Generate CSV'"
                                        [formGroup]="formDownload">
                                        <label class="col-sm-4 col-form-label">Pilih SPM</label>
                                        <div class="col-sm-8">
                                            <div class="input-group">
                                                <textarea readonly rows="2" id="pilihSpm"
                                                    name="pilihSpm" formControlName="nomor"
                                                    class="form-control"></textarea>
                                                <div class="input-group-append">
                                                    <button (click)="listSpmModal.toggle()"
                                                        id="btn_modal_spm"
                                                        name="btn_modal_spm" type="button"
                                                        class="btn btn-primary fa fa-search"></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group row"
                                        *ngIf="this.formDownload.value.module == 'SPP' && formDownload.get('module').value != 'Generate CSV'"
                                        [formGroup]="formDownload">
                                        <label class="col-sm-4 col-form-label">Pilih SPP</label>
                                        <div class="col-sm-8">
                                            <div class="input-group">
                                                <textarea readonly rows="2" id="pilihSpp"
                                                    name="pilihSpp" formControlName="nomor"
                                                    class="form-control"></textarea>
                                                <div class="input-group-append">
                                                    <button (click)="listSppModal.toggle()"
                                                        id="btn_modal_spp"
                                                        name="btn_modal_spp" type="button"
                                                        class="btn btn-primary fa fa-search"></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group row" [formGroup]="formDownload" *ngIf="this.formDownload.value.module == 'Laporan Pembayaran BPJS Kesehatan' ">
                                        <label class="col-sm-4 col-form-label">Tgl Awal</label>
                                        <div class="col-sm-8">
                                            <div class="input-group">
                                                <input placeholder="Tgl. Awal Cari" class="form-control" name="dp" ngbDatepicker
                                                       #tglSpd1="ngbDatepicker" formControlName="tanggalAwal"
                                                       onkeypress="(event.keyCode == 8 ? null : event.preventDefault())"
                                                       (keyup)="$event.keyCode == 8 ? formDownload.patchValue({'tanggalAwal': '', 'tanggalAkhir': ''}) : null"
                                                       placement="right"
                                                       [maxDate]="{year: tahunAnggaran, month: 12, day: 31}"
                                                       [minDate]="{year: tahunAnggaran, month: 1, day: 1}">
                                                <div class="input-group-append">
                                                    <button class="btn btn-outline-secondary fa fa-calendar"
                                                            (click)="tglSpd1.toggle()" type="button"
                                                            style="padding-left: 10px; padding-right: 10px;"></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group row" [formGroup]="formDownload" *ngIf="this.formDownload.value.module == 'Laporan Pembayaran BPJS Kesehatan' ">
                                        <label class="col-sm-4 col-form-label">Tgl Akhir</label>
                                        <div class="col-sm-8">
                                            <div class="input-group">
                                                <input placeholder="Tgl. Akhir Cari" class="form-control"name="dp" ngbDatepicker
                                                       #tglSpd2="ngbDatepicker" formControlName="tanggalAkhir"
                                                       onkeypress="(event.keyCode == 8 ? null : event.preventDefault())"
                                                       onkeyup="(event.keyCode == 8 ? this.value = '' : null)" placement="right"
                                                       [maxDate]="{year: tahunAnggaran, month: 12, day: 31}"
                                                       [minDate]="formDownload.value.tanggalAwal">
                                                <div class="input-group-append">
                                                    <button class="btn btn-outline-secondary fa fa-calendar"
                                                            (click)="tglSpd2.toggle()" type="button"
                                                            style="padding-left: 10px; padding-right: 10px;"></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-8">
                                        </div>
                                    </div>

                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="card-footer">
                            <button type="button" class="btn btn-dark" (click)="goBack()"><i class="fa fa-close"></i>
                                Kembali
                            </button>
                            &nbsp;
                            <button type="button" class="btn btn-primary" (click)="download()"
                                [disabled]="submittedDownload"
                                *ngIf="formDownload.get('module').value != 'Generate CSV'"><i class="fa fa-save"></i>
                                Download
                            </button>
                            &nbsp;
                            <button *ngIf="formDownload.get('module').value == 'Generate CSV'" class="btn btn-success"
                                (click)="generateCsv()" [disabled]="!fileSelectedCsv">Generate CSV
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<app-landing-home-footer></app-landing-home-footer>
<app-landing-home-copyright></app-landing-home-copyright>


<div bsModal id="listSkpdOpdModal" #listSkpdOpdModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="listSkpdOpdModal" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <strong class="modal-title">Pilih SKPD</strong>
                <button type="button" class="close" (click)="listSkpdOpdModal.hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="formSearchSkpdOpd" (submit)="refresh(0)">
                    <div class="row">
                        <div class="col-4">
                            <input class="form-control" type="text" placeholder="Kode OPD" formControlName="kodeOpd">
                        </div>
                        <div class="col-5">
                            <input class="form-control" type="text" placeholder="Nama OPD" formControlName="namaOpd">
                        </div>
                        <div class="col-3">
                            <button class="btn btn-success" type="submit"><i class="fa fa-search"></i> Cari
                            </button>
                        </div>
                    </div>

                    <hr>

                    <div class="col-12 overflow-datatable" style="padding: 10px 0 0 0">
                        <div class="table-responsive">
                            <table datatable [dtOptions]="dtOptions[0]" [dtTrigger]="dtTriggers[0]"
                                class="row-border hover table table-bordered"
                                style="table-layout: fixed; width: 100%; font-size: 12px">
                            </table>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer justify-content-center">
                <button class="btn btn-dark" type="button" (click)="listSkpdOpdModal.hide()"><i class="fa fa-close"></i>
                    Tutup
                </button>
                &nbsp;
                <button class="btn btn-primary" type="button" (click)="selectedSkpdOpd()" id="selectedSkpdOpd"><i
                        class="fa fa-check"></i> Pilih
                </button>
            </div>
        </div>
    </div>
</div>

<div bsModal id="listSpdModal" #listSpdModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="listSpdModal" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <strong class="modal-title">Pilih SPD</strong>
                <button type="button" class="close" (click)="listSpdModal.hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <form [formGroup]="formSearchSpd" (submit)="refresh(1)">
                    <div
                        class="col-md-12 col-sm-12 col-lg-12 col-xs-push-12 col-xl-12 text-right align-middle align-content-end">
                        <div class="row" style="margin: 0px;">
                            <div class="outer">
                                <input class="form-control" type="text" placeholder="No. SPD" formControlName="noSpd">
                            </div>
                            <div class="outer">
                                <select name="statusSpd" id="statusSpd" class="form-control" formControlName="status">
                                    <option value="">Status SPD</option>
                                    <option value="PUBLISH">Publish</option>
                                    <option value="VALIDASI">Validasi</option>
                                    <option value="CETAK">Cetak</option>
                                </select>
                            </div>
                            <div class="outer">
                                <input class="form-control" type="text" formControlName="pegawaiTtd" placeholder="Pegawai Ttd.">
                            </div>
                            <div class="outer">
                                <button class="btn btn-primary" type="submit"><i class="fa fa-search"></i> Cari</button>
                            </div>
                        </div>
                    </div>
                    <div
                        class="form-group col-md-12 col-sm-12 col-lg-12 col-xs-push-12 col-xl-12 text-center align-middle align-content-center p-t-10">
                        <div class="table-responsive text-center align-middle align-content-center">
                            <table datatable [dtOptions]="dtOptions[1]" [dtTrigger]="dtTriggers[1]"
                                class="row-border hover table table-bordered table-sm">
                            </table>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer justify-content-center">
                <button class="btn btn-dark" type="button" (click)="listSpdModal.hide()"><i class="fa fa-close"></i>
                    Tutup
                </button>
                &nbsp;
                <button class="btn btn-primary" type="button" (click)="selectedSpd()" id="selectedSpd"><i
                        class="fa fa-check"></i> Pilih
                </button>
            </div>
        </div>
    </div>
</div>

<div bsModal id="listSp2dModal" #listSp2dModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="listSp2dModal" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <strong class="modal-title">Pilih SP2D</strong>
                <button type="button" class="close" (click)="listSp2dModal.hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <form [formGroup]="formSearchSp2d" (submit)="refresh(2)">
                    <div class="row col-12 p-t-20" style="margin: 0px;">
                        <div class="outer">
                            <input class="form-control" type="text" placeholder="No. SP2D" formControlName="noSp2d">
                        </div>
                        <div class="outer">
                            <select name="status" id="statusSp2d" class="form-control" formControlName="jenis">
                                <option value="">Semua Jenis SP2D</option>
                                <option value="up">UP</option>
                                <option value="gu">GU</option>
                                <option value="tu">TU</option>
                                <option value="gaji">LS Belanja Gaji</option>
                                <option value="nokontrak">LS Belanja Barjas (Tanpa Kontrak)</option>
                                <option value="kontrak">LS Belanja Barjas (Dengan Kontrak)</option>
                                <option value="biaya">LS Pembiayaan</option>
                            </select>
                        </div>
                        <div class="outer">
                            <button class="btn btn-success" type="submit"><i class="fa fa-search"></i> Cari</button>
                        </div>
                    </div>
                    <div
                        class="form-group col-md-12 col-sm-12 col-lg-12 col-xs-push-12 col-xl-12 overflow-datatable p-t-10">
                        <div class="table-responsive">
                            <table datatable [dtOptions]="dtOptions[2]" [dtTrigger]="dtTriggers[2]"
                                class="row-border hover table table-bordered"
                                style="table-layout: fixed; width: 100%; font-size: 12px">
                            </table>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer justify-content-center">
                <button class="btn btn-dark" type="button" (click)="listSp2dModal.hide()"><i class="fa fa-close"></i>
                    Tutup
                </button>
                &nbsp;
                <button class="btn btn-primary" type="button" (click)="selectedSp2d()" id="selectedSp2d"><i
                        class="fa fa-check"></i> Pilih
                </button>
            </div>
        </div>
    </div>
</div>

<div bsModal id="listBuktiBayarPajakModal" #listBuktiBayarPajakModal="bs-modal" class="modal fade" tabindex="-1"
    role="dialog" aria-labelledby="listBuktiBayarPajakModal" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <strong class="modal-title">Pilih SPD</strong>
                <button type="button" class="close" (click)="listBuktiBayarPajakModal.hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <form [formGroup]="formSearchSpd" (submit)="refresh(3)">
                    <div
                        class="col-md-12 col-sm-12 col-lg-12 col-xs-push-12 col-xl-12 text-right align-middle align-content-end">
                        <div class="row" style="margin: 0px;">
                            <div class="outer">
                                <input class="form-control" type="text" placeholder="No. SPD" formControlName="spdNo">
                            </div>
                            <div class="outer">
                                <select name="statusSpd" id="statusSpd" class="form-control" formControlName="status">
                                    <option value="">Status SPD</option>
                                    <option value="VALIDASI">Validasi</option>
                                    <option value="CETAK">Cetak</option>
                                </select>
                            </div>
                            <div class="outer">
                                <input class="form-control" type="text" placeholder="Pegawai Ttd.">
                            </div>
                            <div class="outer">
                                <button class="btn btn-primary" type="submit"><i class="fa fa-search"></i> Cari</button>
                            </div>
                        </div>
                    </div>
                    <div
                        class="form-group col-md-12 col-sm-12 col-lg-12 col-xs-push-12 col-xl-12 text-center align-middle align-content-center p-t-10">
                        <div class="table-responsive text-center align-middle align-content-center">
                            <table datatable [dtOptions]="dtOptions[3]" [dtTrigger]="dtTriggers[3]"
                                class="row-border hover table table-bordered table-sm">
                            </table>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer justify-content-center">
                <button class="btn btn-dark" type="button" (click)="listBuktiBayarPajakModal.hide()"><i
                        class="fa fa-close"></i>
                    Tutup
                </button>
                &nbsp;
                <button class="btn btn-primary" type="button" (click)="selectedBuktiBayarPajak()" id="selectedSpd"><i
                        class="fa fa-check"></i> Pilih
                </button>
            </div>
        </div>
    </div>
</div>

<div bsModal id="listTandaTerimaSpmModal" #listTandaTerimaSpmModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="listTandaTerimaSpmModal" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <strong class="modal-title">Pilih Tanda Terima SPM</strong>
                <button type="button" class="close" (click)="listTandaTerimaSpmModal.hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <form [formGroup]="formSearchTandaTerimaSpm" (submit)="refresh(4)">
                    <div class="row col-12 p-t-20" style="margin: 0px;">
                        <div class="outer">
                            <input class="form-control" type="text" placeholder="No. SP2D" formControlName="noSp2d">
                        </div>
                        <div class="outer">
                            <div class="input-group">
                                <input placeholder="Tgl. SP2D Dari" class="form-control" name="dp" ngbDatepicker
                                    #tglSp2d1="ngbDatepicker" formControlName="tanggalSp2dAwal"
                                    onkeypress="(event.keyCode == 8 ? null : event.preventDefault())"
                                    (keyup)="$event.keyCode == 8 ? formSearchTandaTerimaSpm.patchValue({'tanggalSp2dAwal': '', 'tanggalSp2dAkhir': ''}) : null"
                                    placement="bottom" [maxDate]="getMaxMinDate(tglSp2d2)">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary fa fa-close" type="button"
                                        style="padding-left: 10px; padding-right: 10px;"
                                        (click)="clearSp2dDate1();"></button>
                                    <button class="btn btn-outline-secondary fa fa-calendar"
                                        (click)="tglSp2d1.toggle()" type="button"
                                        style="padding-left: 10px; padding-right: 10px;"></button>
                                </div>
                            </div>
                        </div>
                        <div [hidden]="!formSearchTandaTerimaSpm.value.tanggalSp2dAwal" class="outer">
                            <div class="input-group">
                                <input placeholder="Tgl. SP2D Sampai" class="form-control" name="dp" ngbDatepicker
                                    #tglSp2d2="ngbDatepicker" formControlName="tanggalSp2dAkhir"
                                    onkeypress="(event.keyCode == 8 ? null : event.preventDefault())"
                                    onkeyup="(event.keyCode == 8 ? this.value = '' : null)" placement="bottom"
                                    [minDate]="getMaxMinDate(tglSp2d1)">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary fa fa-close" type="button"
                                        style="padding-left: 10px; padding-right: 10px;"
                                        (click)="clearSp2dDate2();"></button>
                                    <button class="btn btn-outline-secondary fa fa-calendar"
                                        (click)="tglSp2d2.toggle()" type="button"
                                        style="padding-left: 10px; padding-right: 10px;"></button>
                                </div>
                            </div>
                        </div>
                        <div class="outer">
                            <select name="status" id="status" class="form-control" formControlName="jenis">
                                <option value="">Semua Jenis SP2D</option>
                                <option value="up">UP</option>
                                <option value="gu">GU</option>
                                <option value="tu">TU</option>
                                <option value="gaji">LS Belanja Gaji</option>
                                <option value="nokontrak">LS Belanja Barjas (Tanpa Kontrak)</option>
                                <option value="kontrak">LS Belanja Barjas (Dengan Kontrak)</option>
                                <option value="biaya">LS Pembiayaan</option>
                            </select>
                        </div>
                        <div class="outer">
                            <button class="btn btn-success" type="submit"><i class="fa fa-search"></i> Cari</button>
                        </div>
                    </div>
                    <div
                        class="form-group col-md-12 col-sm-12 col-lg-12 col-xs-push-12 col-xl-12 text-center align-middle align-content-center p-t-10">
                        <div class="table-responsive text-center align-middle align-content-center">
                            <table datatable [dtOptions]="dtOptions[4]" [dtTrigger]="dtTriggers[4]"
                                class="row-border hover table table-bordered table-sm">
                            </table>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer justify-content-center">
                <button class="btn btn-dark" type="button" (click)="listTandaTerimaSpmModal.hide()"><i class="fa fa-close"></i>
                    Tutup
                </button>
                &nbsp;
                <button class="btn btn-primary" type="button" (click)="selectedTandaTerimaSpm()" id="selectedTandaTerimaSpm"><i
                        class="fa fa-check"></i> Pilih
                </button>
            </div>
        </div>
    </div>
</div>

<div bsModal id="listSpmModal" #listSpmModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="listSpmModal" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <strong class="modal-title">Pilih SPM</strong>
                <button type="button" class="close" (click)="listSpmModal.hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <form [formGroup]="formSearchSpm" (submit)="refresh(5)">
                    <div class="row col-12 p-t-20" style="margin: 0px;">
                        <div class="outer">
                            <input class="form-control" type="text" placeholder="No. SPP"
                                   formControlName="noSpp">
                        </div>
                        <div class="outer">
                            <input class="form-control" type="text" placeholder="No. SPM"
                                   formControlName="noSpm">
                        </div>
                        <div class="outer">
                            <select name="jenis" id="jenis" class="form-control" formControlName="jenis">
                                <option value="">Semua Jenis SPM</option>
                                <option value="up">UP</option>
                                <option value="gu">GU</option>
                                <option value="tu">TU</option>
                                <option value="gaji">LS Belanja Gaji</option>
                                <option value="nokontrak">LS Belanja Barjas (Tanpa Kontrak)</option>
                                <option value="kontrak">LS Belanja Barjas (Dengan Kontrak)</option>
                                <option value="biaya">LS Pembiayaan</option>
                            </select>
                        </div>
                        <div class="outer">
                            <select name="status" id="status" class="form-control" formControlName="status">
                                <option value="">Semua Status</option>
                                <option value="SPM PENGAJUAN">SPM PENGAJUAN</option>
                                <option value="SPM CETAK">SPM CETAK</option>
                                <option value="TANDA TERIMA SPM">TANDA TERIMA SPM</option>
                                <option value="SP2D PENGAJUAN">SP2D PENGAJUAN</option>
                                <option value="SP2D CETAK">SP2D CETAK</option>
                                <option value="SP2D SAH">SP2D SAH</option>
                                <option value="SP2D BATAL SAH">SP2D BATAL</option>
                            </select>
                        </div>
                        <div class="outer">
                            <button class="btn btn-success" type="submit"><i class="fa fa-search"></i> Cari</button>
                        </div>
                    </div>
                    <div
                        class="form-group col-md-12 col-sm-12 col-lg-12 col-xs-push-12 col-xl-12 overflow-datatable p-t-10">
                        <div class="table-responsive">
                            <table datatable [dtOptions]="dtOptions[5]" [dtTrigger]="dtTriggers[5]"
                                class="row-border hover table table-bordered"
                                style="table-layout: fixed; width: 100%; font-size: 12px">
                            </table>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer justify-content-center">
                <button class="btn btn-dark" type="button" (click)="listSpmModal.hide()"><i class="fa fa-close"></i>
                    Tutup
                </button>
                &nbsp;
                <button class="btn btn-primary" type="button" (click)="selectedSpm()" id="selectedSpm"><i
                        class="fa fa-check"></i> Pilih
                </button>
            </div>
        </div>
    </div>
</div>

<div bsModal id="listSppModal" #listSppModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="listSppModal" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <strong class="modal-title">Pilih SPP</strong>
                <button type="button" class="close" (click)="listSppModal.hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <form [formGroup]="formSearchSpp" (submit)="refresh(6)">
                    <div class="row col-12 p-t-20" style="margin: 0px;">
                        <div class="outer" style="margin-right: 3px;">
                            <input class="form-control" type="text" placeholder="No. SPP"
                                   formControlName="noSpp" numbersOnly>
                        </div>
                        <div class="outer">
                            <div class="input-group">
                                <input placeholder="Tgl. SPP" class="form-control" name="dp" ngbDatepicker
                                       #dateSpp="ngbDatepicker" readonly formControlName="tanggalSppUnformated">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary fa fa-close" type="button"
                                            style="padding-left: 10px; padding-right: 10px;"
                                            (click)="deleteDate()"></button>
                                    <button class="btn btn-outline-secondary fa fa-calendar"
                                            (click)="dateSpp.toggle()" type="button"
                                            style="padding-left: 10px; padding-right: 10px;"></button>
                                </div>
                            </div>
                        </div>
                        <div class="outer">
                            <select name="jenisSpp" id="jenisSpp" class="form-control"
                                    formControlName="jenisSpp">
                                <option value="">SEMUA JENIS</option>
                                <option value="UP">UP</option>
                                <option value="GU">GU</option>
                                <option value="TU">TU</option>
                                <option value="BELANJA GAJI">BELANJA GAJI</option>
                                <option value="BELANJA NON KONTRAK">BELANJA NON KONTRAK</option>
                                <option value="BELANJA KONTRAK">BELANJA KONTRAK</option>
                                <option value="BIAYA">BIAYA</option>
                                <option value="RESTITUSI">RESTITUSI</option>
                            </select>
                        </div>
                        <div class="outer">
                            <select name="status" id="status" class="form-control"
                                    formControlName="statusGlobal">
                                <option value = " ">SEMUA</option>
                                <option value= "SP2D SAH">SP2D SAH</option>
                                <option value= "SP2D BATAL SAH">SP2D BATAL SAH</option>
                                <option value= "SP2D CETAK">SP2D CETAK</option>
                                <option value= "SP2D PENGAJUAN">SP2D PENGAJUAN</option>
                                <option value= "SPM CETAK">SPM CETAK</option>
                                <option value= "SPM PENGAJUAN">SPM PENGAJUAN</option>
                                <option value= "SPP CETAK">SPP CETAK</option>
                                <option value= "SPP PENGAJUAN">SPP PENGAJUAN</option>
                            </select>
                        </div>
                        
                        <div class="outer">
                            <button class="btn btn-success" type="submit"><i class="fa fa-search"></i> Cari</button>
                        </div>
                    </div>
                    <div
                        class="form-group col-md-12 col-sm-12 col-lg-12 col-xs-push-12 col-xl-12 overflow-datatable p-t-10">
                        <div class="table-responsive">
                            <table datatable [dtOptions]="dtOptions[6]" [dtTrigger]="dtTriggers[6]"
                                class="row-border hover table table-bordered"
                                style="table-layout: fixed; width: 100%; font-size: 12px">
                            </table>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer justify-content-center">
                <button class="btn btn-dark" type="button" (click)="listSppModal.hide()"><i class="fa fa-close"></i>
                    Tutup
                </button>
                &nbsp;
                <button class="btn btn-primary" type="button" (click)="selectedSpp()" id="selectedSpp"><i
                        class="fa fa-check"></i> Pilih
                </button>
            </div>
        </div>
    </div>
</div>

<ngx-spinner name="downloadDoc" bdOpacity="1" bdColor="rgba(0,61,121,0.78)" size="large" color="#ffffff" align="center"
             type="ball-running-dots" [fullScreen]="true">
    <p style="color: #ffffff; text-align: center"><br><br>Download Document...</p>
</ngx-spinner>
