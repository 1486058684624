import {DOCUMENT} from '@angular/common';
import {Component, OnInit, Inject} from '@angular/core';
import {Menu, NavService} from '../../../service/nav.service';
import {environment} from 'src/environments/environment';
import {ToastrService} from 'ngx-toastr';
import {CurrentUser} from '../../../../_model/auth/authentication.model';
import {AuthenticationService} from '../../../../_services/auth/authentication.service';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
    public menuItems: Menu[];
    public openSide: boolean = false;
    public activeItem: string = 'home';
    public active: boolean = false;
    public activeChildItem: string = '';
    public overlay: boolean = false;

    public titleEsp2d: string = '';
    public typeEsp2d: string = '';
    public urlEsp2d: string = '';

    constructor(
        private toastr: ToastrService,
        private navServices: NavService,
        private authentication: AuthenticationService,
        @Inject(DOCUMENT) private docGo: Document) {
    }

    ngOnInit() {
        const currentUser: CurrentUser = this.authentication.currentUser;
        this.navServices.items.subscribe(menuItems => {
            this.menuItems = menuItems;
        });
        if (currentUser?.access_token) {
            this.isActiveEsp2d();
            this.navServices.getNavigationHeader().subscribe((response) => {
                if (response.status === 200) {

                    console.log('Response Menu Header Nav');
                    console.log(response);
                    const menuAplikasi: Menu =
                        {
                            title: 'Aplikasi', megaMenu: true, megaMenuType: 'small', type: 'sub', children: response.body
                        };
                    const manual: Menu = {title: 'Manual', megaMenu: true, megaMenuType: 'small', type: 'sub', children: [{title: 'Manual SPD'}]};
                    // const helpdesk: Menu = {title: 'Helpdesk', type: 'url', path: 'https://devsipddki.jakarta.go.id/sipkd/ref/#/helpdesk'};
                    const helpdesk: Menu = {title: 'Helpdesk', type: 'link', path: '/helpdesk'};
                    const profile: Menu = {title: 'Profil', type: 'link', path: '/profile'};
                    const documentDownload: Menu = {title: 'Doc', type: 'link', path: '/document-download'};
                    const espd2: Menu = {title: this.titleEsp2d, type: this.typeEsp2d, path: this.urlEsp2d};
                    if (this.menuItems.length == 4) {
                        this.menuItems.push(menuAplikasi);
                        this.menuItems.push(helpdesk);
                        this.menuItems.push(profile);
                        this.menuItems.push(documentDownload);
                        this.menuItems.push(espd2);   
                    }
                } else {
                    this.toastr.warning('Gagal Mengambil List Menu');
                }
            }, error => {
                console.log(error);
                this.toastr.error('Gagal Mengambil List Menu', 'Kesalahan Server');
            });
        }
    }

    toggleSidebar() {
        this.openSide = !this.openSide;
    }

    closeOverlay() {
        this.openSide = false;
    }

    // For Active Main menu in Mobile View
    setActive(menuItem) {
        if (this.activeItem === menuItem) {
            this.activeItem = '';
        } else {
            this.activeItem = menuItem;
        }
    }

    isActiveEsp2d() {
        this.navServices.getNavigationEsp2d().subscribe((response) => {
            if (response.body && response.body.length > 0) {
                const firstElement = response.body[0];
                this.titleEsp2d = firstElement.title;
                this.typeEsp2d = firstElement.type;
                this.urlEsp2d = firstElement.path;

                
            } else {
                this.titleEsp2d = '';
                this.typeEsp2d = '';
                this.urlEsp2d = '';
            }
        }, error => {
            console.log(error);
        });
    }    

    isActive(item) {
        return this.activeItem === item;
    }

    // For Active Child Menu in Mobile View
    setChildActive(subMenu: Menu) {
        // this.docGo.location.href = window.location.origin + environment.siteUrl + module;
        console.log('Klik Sub');
        console.log(subMenu);
        console.log(`OROGIN: ${window.location.origin}`);
        console.log(`PRODUCTION: ${window.location.protocol}//${window.location.hostname}${subMenu.path}`);
        console.log(`DEV: ${window.location.protocol}//${window.location.hostname}:${subMenu.port}/#`);
        if (environment.production && subMenu.path) {
            this.docGo.location.href = `${window.location.protocol}//${window.location.hostname}:${window.location.port}${subMenu.path}`;
        } else {
            if (subMenu.path && subMenu.port) {
                this.docGo.location.href = `${window.location.protocol}//${window.location.hostname}:${subMenu.port}/#`;
            }
        }

        if (this.activeChildItem === subMenu?.title) {
            this.activeChildItem = '';
        } else {
            this.activeChildItem = subMenu?.title;
        }
    }

    ischildActive(subMenu) {
        return this.activeChildItem === subMenu;
    }


}
