export class FormatRupiah {

    public format(angka: any, prefix: string) {
        const numberString = angka.toString().replace(/[^.\d]/g, '').toString();
        const split = numberString.split('.');
        const sisa = split[0].length % 3;
        let rupiah = split[0].substr(0, sisa);
        const ribuan = split[0].substr(sisa).match(/\d{3}/gi);

        // tambahkan titik jika yang di input sudah menjadi angka ribuan
        if (ribuan) {
            const separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');
        }

        rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah;
        return prefix === undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
    }

    public formatNumber(n) {
        // format number 1000000 to 1,234,567
        return n.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }

    public formatCurrency(input, blur) {
        //
        // appends Rp to value, validates decimal side
        // and puts cursor back in right position.

        // get input value
        // var input_val = input.val();
        var input_val = input.target.value;

        if (input_val === '00' || (input_val === '' && blur === 'blur')) {
            input_val = '0';
        }

        // don't validate empty input
        if (input_val === '') {
            return;
        }

        // original length
        var original_len = input_val.length;

        // initial caret position 
        // var caret_pos = input.prop("selectionStart");
        var caret_pos = input.target.selectionStart;

        // check for decimal
        if (input_val.indexOf(',') >= 0) {

            // get position of first decimal
            // this prevents multiple decimals from
            // being entered
            var decimal_pos = input_val.indexOf(',');

            // split number by decimal point
            var left_side = input_val.substring(0, decimal_pos);
            var right_side = input_val.substring(decimal_pos);

            // add commas to left side of number
            left_side = this.formatNumber(left_side);

            // validate right side
            right_side = this.formatNumber(right_side);

            // On blur make sure 2 numbers after decimal
            if (blur === 'blur') {
                right_side += '00';
            }

            // Limit decimal to only 2 digits
            right_side = right_side.substring(0, 2);

            // join number by .
            input_val = '' + left_side + ',' + right_side;

        } else {
            // no decimal entered
            // add commas to number
            // remove all non-digits
            input_val = this.formatNumber(input_val);
            input_val = '' + input_val;

            // final formatting
            if (blur === 'blur') {
                // input_val += ",00";
            }
        }

        // send updated string to input
        // input.val(input_val);
        input.target.value = input_val;

        // put caret back in the right position
        var updated_len = input_val.length;
        caret_pos = updated_len - original_len + caret_pos;
        // input[0].setSelectionRange(caret_pos, caret_pos);
        input.target.setSelectionRange(caret_pos, caret_pos);
    }

    currencyWhileTyping(input) {
        let value = input.target.value;
        if (value != '') {
            //return '$' + value.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            var decimalCount;
            value.match(/\,/g) === null ? decimalCount = 0 : decimalCount = value.match(/\,/g);

            if (decimalCount.length > 1) {
                value = value.slice(0, -1);
            }

            var components = value.toString().split(',');
            if (components.length === 1) {
                components[0] = value;
            }
            components[0] = components[0].replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            if (components.length === 2) {
                components[1] = components[1].replace(/\D/g, '').replace(/^\d{3}$/, '');
            }

            if (components.join(',') != '') {
                value = components.join(',');
            } else {
                value = '';
            }
        }
    }


    getNumberRegEx(value) {
        //
        var res = value.replace("Rp. ", "").replace(/\./g, "").replace(/\,/g, ".");
        return res;
    }

}
