<header class="ecommerce">
    <div class="container">
        <div class="row">
            <div class="col">
                <nav>
                    <a href="#" class="d-inline-block m-r-auto">
                        <img src="assets/images/logo/dki_logo.png" width="100px" alt="" class="img-fluid">
                        <strong style="color: black;">SIPD DKI JAKARTA</strong>
                    </a>
                    <app-menu></app-menu>
                </nav>
            </div>
        </div>
    </div>
</header>

<section id="feature" class="saas1 service">
    <div class="about-chat">
        <div class="container">
            <div class="row" style="padding: 20px;">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4>Profil</h4>
                        </div>
                        <div class="card-body">
                            <form class="form theme-form" [formGroup]="formDataUser">
                                <!-- kolom 1 -->
                                <div class="row p-t-10">
                                    <div class="col col-xl-6 col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                        <div class="form-group row">
                                            <label class="col-sm-4 col-form-label">Username</label>
                                            <div class="col-sm-8">
                                                <div class="input-group input-group-sm">
                                                    <input class="form-control" formControlName="penggunaLogin">
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="form-group row">
                                            <label class="col-sm-4 col-form-label">NRK</label>
                                            <div class="col-sm-8">
                                                <div class="input-group input-group-sm">
                                                    <input class="form-control">
                                                </div>
                                            </div>
                                        </div> -->
                                        <div class="form-group row">
                                            <label class="col-sm-4 col-form-label">Nama</label>
                                            <div class="col-sm-8">
                                                <div class="input-group input-group-sm">
                                                    <input class="form-control" formControlName="namaPengguna">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-4 col-form-label">NIP</label>
                                            <div class="col-sm-8">
                                                <div class="input-group input-group-sm">
                                                    <input class="form-control" formControlName="nipPengguna">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-4 col-form-label">Email</label>
                                            <div class="col-sm-8">
                                                <div class="input-group input-group-sm">
                                                    <input class="form-control" formControlName="email">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="card-footer">
                            <button type="button" class="btn btn-dark" (click)="goBack()"><i class="fa fa-close"></i>
                                Kembali
                            </button>
                            &nbsp;
                            <button type="button" class="btn btn-primary" (click)="modalSuntingPassword.show();"><i
                                    class="fa fa-save"></i>
                                Ganti Password
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<app-landing-home-footer></app-landing-home-footer>
<app-landing-home-copyright></app-landing-home-copyright>

<div bsModal id="modalSuntingPassword" #modalSuntingPassword="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <strong>Sunting Password</strong>
                <!-- <button type="button" class="close" (click)="modalSuntingPassword.hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button> -->
            </div>
            <div class="modal-body">
                <div class="row" style="font-size: 13px;">
                    <div class="col col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12" [formGroup]="formGantiPass">
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Password Baru</label>
                            <div class="col-sm-9 input-group">
                                <input id="passwordBaru" name="passwordBaru" [type]="passBaruShow ? 'text' : 'password'"
                                       [ngClass]="{ 'is-invalid': submittedGantiPass && formGantiPass.controls.passBaru.errors}"
                                       class="form-control" formControlName="passBaru">
                                <div class="input-group-append" (click)="passBaruShow = !passBaruShow">
                                    <span class="input-group-text">
                                        <i class="fa"
                                           [ngClass]="{ 'fa-eye-slash': !passBaruShow, 'fa-eye': passBaruShow }"></i>
                                    </span>
                                </div>
                                <div *ngIf="submittedGantiPass && formGantiPass.controls.passBaru.errors"
                                     class="invalid-feedback pd-valid-form">
                                    <div *ngIf="formGantiPass.controls.passBaru.errors?.required"
                                         class="text text-danger pd-valid-form">
                                        Kolom Ini Wajib Diisi
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12" [formGroup]="formGantiPass">
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Konfirmasi Password
                                Baru</label>
                            <div class="col-sm-9 input-group">
                                <input id="konfirmasiPasswordBaru" name="konfirmasiPasswordBaru"
                                       [type]="konfirmasPassBaruShow ? 'text' : 'password'"
                                       [ngClass]="{ 'is-invalid': submittedGantiPass && (formGantiPass.controls.konfirmasiPassBaru.errors || (formGantiPass.get('passBaru').value !== formGantiPass.get('konfirmasiPassBaru').value))}"
                                       class="form-control" formControlName="konfirmasiPassBaru">
                                <div class="input-group-append"
                                     (click)="konfirmasPassBaruShow = !konfirmasPassBaruShow">
                                    <span class="input-group-text">
                                        <i class="fa"
                                           [ngClass]="{ 'fa-eye-slash': !konfirmasPassBaruShow, 'fa-eye': konfirmasPassBaruShow }"></i>
                                    </span>
                                </div>
                                <div *ngIf="submittedGantiPass && (formGantiPass.controls.konfirmasiPassBaru.errors || (formGantiPass.get('passBaru').value !== formGantiPass.get('konfirmasiPassBaru').value))"
                                     class="invalid-feedback pd-valid-form">
                                    <div *ngIf="formGantiPass.controls.konfirmasiPassBaru.errors?.required"
                                         class="text text-danger pd-valid-form">
                                        Kolom Ini Wajib Diisi
                                    </div>
                                    <div *ngIf="formGantiPass.get('passBaru').value !== formGantiPass.get('konfirmasiPassBaru').value"
                                         class="text text-danger pd-valid-form">
                                        Password Tidak Sama
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-dark" (click)="modalSuntingPassword.hide()">
                    <i class="fa fa-arrow-left"></i>
                    <span> Batal</span>
                </button>
                &nbsp;
                <button type="button" class="btn btn-success" (click)="gantiPassword()" [disabled]="submittedGantiPass">
                    <i class="fa fa-save"></i>
                    <span> Ganti Password</span>
                </button>
            </div>
        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>
