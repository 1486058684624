import { Injectable } from '@angular/core';
import { HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
    constructor(
        private loginService: AuthenticationService,
        private router: Router) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        let header = new HttpHeaders();
        const urlBased = req.url;

        if (this.loginService.currentUser) {
            header = header.append('Authorization', `Bearer ${this.loginService.currentUser.access_token}`);
            if (!urlBased.match('\/(login)\/[a-zA-Z0-9.?=&&_-]*')) {
                const newRequest = req.clone({
                    headers: header
                });
                return next.handle(newRequest);
            } else {
                const newRequest = req.clone();
                return next.handle(newRequest);
            }
        } else {
            const newRequest = req.clone();
            return next.handle(newRequest);
        }
    }
}
