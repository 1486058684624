import { Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Injectable()
export class NgbDateMomentAdapter extends NgbDateAdapter<moment.Moment> {

    fromModel(date: any): NgbDateStruct {
        if (!date) {
            return null;
        } else {
            const year = moment(date).format('YY');
            const month = moment(date).format('MM');
            const day = moment(date).format('DD');
            return { year: Number(year), month: Number(month), day: Number(day) };
        }
    }

    toModel(date: NgbDateStruct): moment.Moment {
        if (!date) {
            return null;
        }
        return moment(date.year + '-' + date.month + '-' + date.day, 'YYYY-MM-DD');
    }

    toUnixMoment(date: NgbDateStruct) {
        if (!date) {
            return null;
        }
        return moment(date.year + '-' + date.month + '-' + date.day).format();
    }

    selectToString(date: NgbDateStruct): string {
        if (!date.year && !date.month && !date.day) {
            return null;
        } else {
            const month = date.month.toString().length === 1 ? `0${date.month}` : date.month.toString();
            const day = date.day.toString().length === 1 ? `0${date.day}` : date.day.toString();
            return (date.year + '-' + month + '-' + day);
        }
    }
}
